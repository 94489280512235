import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import "./headerComponent.css";
import { HOSPITAL_LIST_API, API_BASE_URL3, LOGOUT_URL } from "../../utils/constant";
import LOGO from "../../assets/images/logo-max.png";
import DR_DEFAULT_IMG from "../../assets/images/user.png";
import { Navbar, NavDropdown, Image, Row, Col } from "react-bootstrap";
import LS_SERVICE from "../../utils/localStorage";
import poweredLogo from '../../assets/images/main-logo.png';
import ToastComponent from "../toast/toast";

class HeaderComponent extends Component {

  state = {
    doctor: [],
    authorization_token: '',
    doctor_name: '',
    name: '',
    doctor_profile_picture: '',
    isProfileDropOpen: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
  };

  constructor(props) {
    super(props);
    if (!LS_SERVICE.get("doctor") || !LS_SERVICE.get("authorization")) {
      LS_SERVICE.clear();
      this.props.history.push("/");
    }
    else {
      this.state = {
        doctor: LS_SERVICE.get("doctor"),
        authorization_token: LS_SERVICE.get("authorization"),
        doctor_name: LS_SERVICE.get("doctor").name,
        name: LS_SERVICE.get("doctor").first_name,
        doctor_profile_picture: LS_SERVICE.get("doctor").profile_picture,
      };
    }
  }

  navLogoClickHandler = (e) => {
    e.preventDefault();
    const isLoggedIn = LS_SERVICE.has("uid");
    if (isLoggedIn) this.props.history.push("/patient-lists");
    else this.props.history.push("/");
  };

  handleProfile = () => {
    this.props.history.push("/profile")
  }

  handleLogout = () => {
    const callLogoutMsg = "You cannot logout as a video call is going on, end the call to logout from application!";
    if (document.getElementById('endconsult_call_btn') !== null ||
      !document.getElementById('floating_vc_dialog').classList.contains('hidden')) {
      this.setState({
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: ""
      }, () => {
        this.setState({
          toastSuccessClass: "bg-danger",
          toast: true,
          toastmsg: callLogoutMsg
        });

        setTimeout(() => {
          this.setState({
            toast: false,
            toastmsg: ""
          });
        }, 5000);
      });
      return false;
    }

    const Authorization = this.state.authorization_token;
    axios
      .get(LOGOUT_URL, {
        headers: {
          Authorization: Authorization,
        },
      })
      .then((response) => {
        LS_SERVICE.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  };

  handleProfileDropdown = () => {
    this.setState({
      isProfileDropOpen: !this.state.isProfileDropOpen
    })
  }

  render() {
    return (
      <Navbar bg="light" className="justify-content-between">
        <div className="left-align">
          <Navbar.Brand href="">
            <Row className="align-items-center">
              <Col className="pr-0 header-logo">
                <span onClick={(e) => this.navLogoClickHandler(e)}>
                  <img
                    src={LOGO}
                    alt="myhealthcare-logo"
                    className="mhc-logoheader"
                  />
                </span>
              </Col>
              <Col>
                <p className="h6 p-0 m-0 mb-2 vc-name">
                  MH Doctor - Virtual Consult Platform
                  </p>
              </Col>
            </Row>
          </Navbar.Brand>
        </div>
        <div className="right-align">
          <Row className="align-items-center">
            <Col className="pr-0">
              <NavDropdown
                className="text-white pull-right"
                title={this.state.doctor_name}
                id="nav-healthdropdown"
              >
                <NavDropdown.Item onClick={this.handleProfile}>
                  View Profile
                  </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleLogout}>
                  Logout
                  </NavDropdown.Item>

              </NavDropdown>
            </Col>
            <Col className="pl-0 header-doctorimg">
              <Image
                src={
                  this.state.doctor_profile_picture !== ""
                    ? this.state.doctor_profile_picture
                    : DR_DEFAULT_IMG
                }
                roundedCircle
                style={{ height: "50px" }}
              />
            </Col>
            {/* <Col className="header-powered-col">
              <div className='d-flex align-items-center justify-content-between header-powered'>
                <p>powered by</p>
                <img src={poweredLogo} />
              </div>
            </Col> */}
          </Row>
        </div>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />

      </Navbar>
    );
  }
}

export default withRouter(HeaderComponent);
