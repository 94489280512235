import React, { Component } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import $ from 'jquery';
import axios from 'axios';
// import Moment from 'react-moment';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
// import '../../assets/styles/popup.css';
import '../../assets/styles/style.css';
import '../../assets/styles/responsive.css';
import {
    DOCTORS_MONTHLIST, MONTHS, DOCTORS_APPOINTMENTS, DOCTORS_APPOINTMENT_BOOKING, DOCTORS_HOSPITAL_DAY,
    BOOKING_STATUSES, API_BASE_URL, LOADER_RED, LOADER, PATIENT_SEARCH, JQUERYLABELS, PAY_ON_ARRIVAL, ISD_CODES
} from '../../utils/constant';
import { Container, Row, Col, Badge, Tab, Card, Button, Accordion } from "react-bootstrap";
import HeaderComponent from '../../components/header/headerComponent';
import SubHeaderComponent from '../../components/sub-header/subHeaderComponent';
import LS_SERVICE from '../../utils/localStorage';
import Popup from "reactjs-popup";
import ToastComponent from '../../components/toast/toast';
import calendar from "../../assets/images/ic-calendar.png";

const TAB_STATUS = { 'PATIENT': 1, 'BOOKING': 2 };

class MyCalendarComponent extends Component {

    state = {
        calendarEvents: [],
        hospital_id: null,
        hospital_key: 0,
        hospital_name: 'loading',
        hospital_start: 0,
        hospital_end: 0,
        hospital_appointments: '',
        hospital_total_slots: 0,
        appointment_page: 1,
        total_pages: 1,
        noMoreData: false,
        isRequestProcessing: false,
        INTERVAL: '',
        hospital_appointments_data: [],
        hospital_list: [],
        hospital_list_date: '',
        hospital_list_data_loaded: false,
        hospital_data_loaded: false,
        hospital_slots_data: [],
        appointment_blockedSlot: [],
        slots_Array: [],
        tab_status: TAB_STATUS.PATIENT,
        isPatientTabActive: true,
        isBookingTabActive: false,
        openBookPopup: false,
        openRegisterConfirmPopup: false,
        openRegisterIsMissedPopup: false,
        openPatientSearchListPopup: false,
        newUserFlag: true,
        patientExistLoaded: false,
        patientExist: false,
        patientExistData: [],
        timeslot_data: '',
        booking_date: '',
        timeslot_id: '',
        patient_name: '',
        mobile_number: '',
        isd_code_list: [],
        isd_code: 91,
        patient_id: '',
        first_name: '',
        last_name: '',
        regPatButDisabled: true,
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: "",
        startTime: ''
    }

    constructor(props) {
        super(props);
        this.handlePopupOpenClose = this.handlePopupOpenClose.bind(this);
        this.handleBookingButClick = this.handleBookingButClick.bind(this);
        this.mobleNumSearch = this.mobleNumSearch.bind(this);
        this.registerPatientSubmit = this.registerPatientSubmit.bind(this);
        this.registePatientConfirm = this.registePatientConfirm.bind(this);
        this.handSearchExistPatients = this.handSearchExistPatients.bind(this);
    }

    bookingTime(booking_time) {
        if (!booking_time)
            return null;

        var timeString = booking_time;
        var H = +timeString.substr(0, 2);
        var h = (H % 12) || 12;
        var ampm = H < 12 ? " AM" : " PM";
        timeString = h + timeString.substr(2, 3) + ampm;
        return timeString;
    }

    async getCalendarData(fetchInfo, successCallback, failureCallback) {

        try {
            if (fetchInfo) {
                const endMon = fetchInfo.end.toLocaleDateString("default", { month: 'short' });
                const currMonNum = MONTHS.indexOf(endMon) < 10 ? '0' + MONTHS.indexOf(endMon) : MONTHS.indexOf(endMon);
                let date = fetchInfo.end;
                date = date.toLocaleDateString("zh-Hans-CN", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
                date = date.split("/");
                date[1] = currMonNum;
                date = date.join("-");
                const todayDate = new Date().toLocaleDateString("zh-Hans-CN", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }).replace(/\//gm, '-');

                const Authorization = LS_SERVICE.get('authorization');

                successCallback(
                    await axios.get(DOCTORS_MONTHLIST + date, {
                        dataType: "json",
                        headers: {
                            'Authorization': Authorization
                        },
                    }).then(response => {
                        let resData = response.data;
                        let data = [];
                        // const { hospital_key } = this.state;
                        let totalSlotsArr = [];
                        resData['calendar'].forEach((value, key) => {
                            if (value['total_slots'] > 0) {
                                let val = {
                                    // title: '' + value['hospitals'][hospital_key]['appointment_count'] + '/' + value['total_slots'],
                                    title: '' + value['appointment_count'] + '/' + value['total_slots'],
                                    start: value['day'],
                                    className: `${value['day'] === todayDate ? 'today' : ''}`,
                                }
                                data.push(val);
                                totalSlotsArr.push({ date: val.start, slots: val.title });
                            }
                            LS_SERVICE.set('slots_array', totalSlotsArr);
                        });
                        return data;
                    }).catch(error => {
                        if (error && error.response && error.response.status === 401) {
                            LS_SERVICE.clear();
                            this.props.history.push("/");
                        }
                    })
                );

            }
        } catch {
            console.log('something went wrong');
        }
    }

    componentWillMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
            "authorization"
        );
        let { hospital_key } = this.state;
        const hospitals = LS_SERVICE.get('hospitals');
        hospitals.forEach((e, i) => {
            if (e.id === LS_SERVICE.get('active_hospital').id) {
                hospital_key = i
            }
        });

        this.setState({
            hospital_id: LS_SERVICE.get('active_hospital').id,
            hospital_key
        })
    }

    componentDidMount() {
        const date = new Date().toLocaleDateString("zh-Hans-CN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replace(/\//gm, '-');
        // this.setState({
        //     INTERVAL: (setInterval(() => {
        //         if (LS_SERVICE.has('slots_array'))
        this.handleCalendarDateClick(date, true);
        //     }, 1000))
        // })
    }

    componentWillUnmount() {
        this.cleanupListener();
    }

    addScrollEvent = () => {
        const elem = document.querySelector(".scroll_loader");
        if (elem !== null)
            elem.addEventListener("scroll", this.handleScroll, true);
    };

    cleanupListener = () => {
        const elem = document.querySelector(".scroll_loader");
        if (elem !== null)
            elem.removeEventListener("scroll", this.handleScroll, true);
    };

    handleScroll = () => {
        const lastLi = document.querySelector(
            ".scroll_loader > div > div > div.card-body:last-child"
        );
        const scrollWin = document.querySelector(".scroll_loader");
        if (lastLi === null) return;
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        // let lastLiOffset = lastLi.offsetTop;
        // var pageOffset = scrollWin.offsetTop + scrollWin.innerHeight;
        let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
        if (pageOffset > lastLiOffset) {
            // debugger
            this.loadMore();
        }
    };

    loadMore = () => {
        const { noMoreData, isRequestProcessing } = this.state;

        if (noMoreData) return;
        if (isRequestProcessing === true) return;

        this.setState({
            isRequestProcessing: true,
        });

        this.setState(
            (prevState) => ({
                appointment_page: prevState.appointment_page + 1,
                scrolling: true,
            }),
            () => {
                this.appointmentList(LS_SERVICE.get('hospital_listdata'))
            }
        );
    };

    slotsArrayNoDate = () => {
        const navtabs = $('.nav-tabscal > div').get();
        navtabs.forEach(e => {
            $(e).find('a').addClass('disabled');
        })

        this.setState({
            hospital_appointments_data: [],
            hospital_data_loaded: true,
            hospital_slots_data: [],
            hospital_name: 'No Slots',
            hospital_start: 0,
            hospital_end: 0,
            hospital_appointments: 0
        })
    }

    handleCalendarDateClick(date, firstClick = false) {
        // if (this.state.INTERVAL !== "") {
        //     clearInterval(this.state.INTERVAL)
        // }

        // if (firstClick) {
        //     if (LS_SERVICE.has('slots_array')) {
        //         let slotsArr = []
        //         slotsArr = LS_SERVICE.get('slots_array');
        //         if (slotsArr.length === 0) {
        //             this.slotsArrayNoDate();
        //             return;
        //         };
        //     }
        // }

        // if (LS_SERVICE.has('slots_array')) {
        //     let slotsArr = []
        //     slotsArr = LS_SERVICE.get('slots_array');
        //     slotsArr = slotsArr.filter(e => e.date === date);
        //     if (slotsArr.length === 0) {
        //         this.slotsArrayNoDate();
        //         return;
        //     }
        // } else {
        //     this.slotsArrayNoDate();
        //     return;
        // }

        if (this.state.isPatientTabActive) {
            this.addScrollEvent();
        } else {
            this.cleanupListener();
        }

        const navtabs = $('.nav-tabscal > div').get();

        navtabs.forEach(e => {
            $(e).find('a').data('date', date).removeClass('disabled');
        })

        LS_SERVICE.set('calDateClicked', date);

        this.setState(
            {
                hospital_appointments_data: [],
                hospital_slots_data: [],
                appointment_page: 1,
                total_pages: 1,
            }, () => this.hospitalList(date)
        )
    }

    hospitalList(date) {
        this.setState({
            hospital_list_data_loaded: false,
            hospital_data_loaded: false
        })

        // const total_slots = this.getSlotsData(date);

        // debugger;

        const Authorization = LS_SERVICE.get('authorization');

        axios.get(DOCTORS_HOSPITAL_DAY + date, {
            dataType: "json",
            headers: {
                'Authorization': Authorization
            },
        }).then(response => {
            const data = response.data;
            // const hospitalData = data.hospitals[this.state.hospital_key];

            let { hospital_name,
                hospital_start,
                hospital_end,
                hospital_appointments,
                hospital_total_slots,
                hospital_list,
                hospital_list_data_loaded,
                hospital_list_date } = this.state;


            // const { arrived, completed, no_show, upcoming, cancelled } = hospitalData.appointments;

            // hospital_name = hospitalData.name;
            // hospital_start = hospitalData.start_time;
            // hospital_end = hospitalData.end_time;
            // hospital_appointments = hospitalData.appointment_count;
            // hospital_total_slots = total_slots;
            hospital_list = data.hospitals;
            hospital_list_date = data.date;

            LS_SERVICE.set('hospital_listdata', data);

            if (hospital_list.every(e => e.total_slots === 0)) {
                this.slotsArrayNoDate();
                return;
            }

            this.setState({
                hospital_name,
                hospital_start,
                hospital_end,
                hospital_appointments,
                hospital_total_slots,
                hospital_list,
                hospital_list_date,
                hospital_list_data_loaded: true
            }, () => {
                if (this.state.isPatientTabActive)
                    this.appointmentList(data);
                else
                    this.getAppointmentSlotsList(this.state.hospital_list_date)
            })

        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                LS_SERVICE.clear();
                this.props.history.push("/");
            }
        })
    }

    appointmentList(data) {
        const { appointment_page, hospital_id } = this.state;
        // debugger
        const Authorization = LS_SERVICE.get('authorization');
        axios.get(`${DOCTORS_APPOINTMENTS}${data.date}&hospital_id=${hospital_id}&booking_status=${BOOKING_STATUSES.ALL}&page=${appointment_page}`, {
            dataType: "json",
            headers: {
                'Authorization': Authorization
            },
        }).then(response => {
            // debugger
            // let appointmentsData = response.data.appointments;
            const dataNew = response.data.appointments;
            const tPages = response.data.pagination.total_pages;
            const noData = response.data.pagination.links.next === "" ? true : false;
            const currentPage = response.data.pagination.current_page;
            const {
                hospital_appointments_data
            } = this.state;

            // debugger;

            this.setState({
                hospital_appointments_data: currentPage === 1 ? [...dataNew] : [...hospital_appointments_data, ...dataNew],
                hospital_data_loaded: true,
                total_pages: tPages,
                noMoreData: noData,
                isRequestProcessing: false,
            }, () => this.addScrollEvent())
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                LS_SERVICE.clear();
                this.props.history.push("/");
            }
        })
    }

    getSlotsData(date) {
        this.setState({ hospital_total_slots: 0 })
        let slots_data = LS_SERVICE.has('slots_array') ? LS_SERVICE.get('slots_array') : null;
        if (slots_data === null) return;
        slots_data = slots_data.filter(e => e.date === date);
        slots_data = slots_data.length > 0 ? slots_data[0].slots.split('/')[1] : this.state.hospital_total_slots;
        return slots_data;
    }

    getISDCodes(hospitalData) {
        const Authorization = LS_SERVICE.get('authorization');
        axios.get(ISD_CODES, {
            dataType: "json",
            headers: {
                'Authorization': Authorization
            },
        }).then(response => {
            const data = response.data.country_isd;
            let { isd_code_list } = this.state;
            isd_code_list = data;
            this.setState({
                isd_code_list,
                hospital_slots_data: hospitalData,
                hospital_data_loaded: true
            })
        }).catch(error => {
            this.setState({
                hospital_slots_data: ['invalid'],
                isd_code_list: [],
                hospital_data_loaded: true
            })
            if (error && error.response && error.response.status === 401) {
                LS_SERVICE.clear();
                this.props.history.push("/");
            }
        })
    }

    getAppointmentSlotsList(date) {
        if (date === undefined) return;
        date = date.split('-').reverse().join('/');

        this.setState({
            hospital_data_loaded: false,
            hospital_slots_data: []
        })

        const { hospital_id } = this.state;

        const Authorization = LS_SERVICE.get('authorization');
        axios.get(`${API_BASE_URL}${LS_SERVICE.get('doctor').id}/availability-timeslots?date=${date}&hospital_id=${hospital_id}`, {
            dataType: "json",
            headers: {
                'Authorization': Authorization
            },
        }).then(response => {
            let appointmentsData = response.data.time_slots;
            let data = [];
            let blockedSlotsArr = [];

            if (appointmentsData.blocked.length > 0) {
                appointmentsData.blocked.forEach((e) => {
                    blockedSlotsArr.push(e.id);
                })
            }

            if (appointmentsData.all.length > 0) {
                appointmentsData.all.forEach((e, i) => {
                    let blockedSlots = new Set(blockedSlotsArr);
                    let blockedClass = blockedSlots.has(e.id);
                    e['status'] = blockedClass ? 'lapsed' : 'available';
                    e['slotTime'] = e.slot.split(' ');
                    if (!blockedClass)
                        data.push(e)
                })
            }
            this.getISDCodes(data);
        }).catch(error => {
            this.setState({
                hospital_slots_data: ['invalid'],
                hospital_data_loaded: true
            })
            if (error && error.response && error.response.status === 401) {
                LS_SERVICE.clear();
                this.props.history.push("/");
            }
        })
    }

    resetToastState() {
        this.setState({
            toastSuccessClass: "bg-danger",
            toast: false,
            toastmsg: "",
        });
    };

    resetPopupState() {
        this.setState({
            openBookPopup: false,
            openRegisterConfirmPopup: false,
            openPatientSearchListPopup: false,
            newUserFlag: true,
            mobile_search: '',
            mobile_number: '',
            first_name: '',
            last_name: ''
        })
    }

    handleBookingButClick(e, i) {
        this.resetPopupState();
        LS_SERVICE.set('slotData', { id: e.target.dataset.id, slot: e.target.dataset.slot, fees: e.target.dataset.fees, index: i })
        this.handlePopupOpenClose(true);
    }

    handlePopupOpenClose(value) {
        this.setState({
            openBookPopup: value,
        });
    };

    handleRegisterConfirmPopup(value) {
        this.setState({
            openRegisterConfirmPopup: value
        })
    }

    handlePatientSearchListPopup(value) {
        this.setState({
            openPatientSearchListPopup: value
        })
    }

    handleRegisterIsMissedPopup(value) {
        this.setState({
            openRegisterIsMissedPopup: value
        })
    }

    handleTabClick(e, status) {
        e.preventDefault();
        let { tab_status } = this.state;
        const date = $(e.target).data().date;
        if (tab_status === status) return;
        switch (status) {
            case 1:
                this.setState({
                    tab_status: status,
                    isPatientTabActive: true,
                    isBookingTabActive: false,
                    hospital_data_loaded: false,
                    appointment_page: 1,
                    hospital_appointments_data: []
                }, this.hospitalList(date))
                break;
            case 2:
                this.setState({
                    tab_status: status,
                    isPatientTabActive: false,
                    isBookingTabActive: true,
                    hospital_data_loaded: false,
                    appointment_page: 1,
                    hospital_slots_data: []
                }, this.getAppointmentSlotsList(date))
                break;
            default:
                break;
        }
    }

    patientNewFormValidate = () => {
        return this.state.mobile_number.length > 9 && this.state.first_name.length > 2 && this.state.last_name.length > 2 ? false : true;
    }

    handleRegPatiendChange = (e) => {
        e.preventDefault();
        if (e.target.name === 'mobile_number')
            e.target.value = e.target.value.replace(/\D/, "");

        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setState({
                regPatButDisabled: this.patientNewFormValidate()
            })
        })

    }

    registerPatientSubmit(e) {
        e.preventDefault();
        let {
            hospital_id,
            booking_date,
            timeslot_id,
            patient_name,
            first_name,
            last_name,
        } = this.state

        let slotData = LS_SERVICE.get('slotData');

        booking_date = slotData.slot.split(' ')[0].split('/').reverse().join('-');
        timeslot_id = slotData.id;
        patient_name = first_name + ' ' + last_name;

        this.setState({
            hospital_id,
            booking_date,
            timeslot_id,
            patient_name,
            openBookPopup: false,
            openRegisterConfirmPopup: true
        })
    }

    handSearchExistPatients(e) {
        JQUERYLABELS(e.target);
        let {
            hospital_id,
            booking_date,
            timeslot_id,
            patient_name,
            mobile_number,
            patient_id
        } = this.state

        let slotData = LS_SERVICE.get('slotData');

        let radioData = $(e.target).data();

        booking_date = slotData.slot.split(' ')[0].split('/').reverse().join('-');
        timeslot_id = slotData.id;
        patient_name = radioData.first_name + ' ' + radioData.last_name;
        mobile_number = radioData.mobile_no;
        patient_id = $(e.target).val();

        this.setState({
            hospital_id,
            booking_date,
            timeslot_id,
            patient_name,
            mobile_number,
            patient_id,
            openPatientSearchListPopup: false,
            openBookPopup: false,
            openRegisterConfirmPopup: true
        })
    }

    payOnArrival({ booking_id, message }) {
        const Authorization = LS_SERVICE.get('authorization');
        const indexVal = LS_SERVICE.get('slotData').index;

        axios
            .get(PAY_ON_ARRIVAL({ booking_id }), {
                headers: {
                    'Authorization': Authorization
                }
            })
            .then((response) => {
                let newData = [...this.state.hospital_slots_data]
                newData[indexVal].status = 'blocked';
                this.setState({
                    openRegisterConfirmPopup: false,
                    hospital_slots_data: newData,
                    toastSuccessClass: 'bg-success',
                    toast: true,
                    toastmsg: message
                })
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 400) {
                    this.setState({
                        openRegisterConfirmPopup: false,
                        toast: true,
                        toastmsg: error.response.data.errors[0].message,
                    });
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
    }

    registePatientConfirm(e) {
        this.resetToastState();

        let elem = "";

        if (e !== "") {
            elem = e.target;
            $(elem).find('span.loader-span').toggleClass('loader-active');
        }

        const indexVal = LS_SERVICE.get('slotData').index;

        const { booking_date,
            timeslot_id,
            patient_name,
            mobile_number,
            isd_code,
            patient_id,
            hospital_id } = this.state;

        // const hospital_id = LS_SERVICE.get('hospitals')[0].id;

        const Authorization = LS_SERVICE.get('authorization');

        axios
            .post(DOCTORS_APPOINTMENT_BOOKING, { hospital_id, booking_date, timeslot_id, patient_name, mobile_number, isd_code, patient_id }, {
                headers: {
                    'Authorization': Authorization
                }
            })
            .then((response) => {
                if (e !== "") {
                    $(elem).find('span.loader-span').toggleClass('loader-active');
                }
                let resData = response.data;
                if (resData.is_missed === 1) {
                    let newData = [...this.state.hospital_slots_data]
                    newData[indexVal].status = 'lapsed';
                    this.setState({
                        openRegisterConfirmPopup: false,
                        openRegisterIsMissedPopup: true,
                        hospital_slots_data: newData
                    })
                } else if (resData.message === "Your booking has been confirmed.") {
                    this.payOnArrival({ booking_id: resData.booking_id, message: resData.message })
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 400) {
                    this.setState({
                        openRegisterConfirmPopup: false,
                        toast: true,
                        toastmsg: error.response.data.errors[0].message,
                    });
                }
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
    }

    mobleNumSearch = e => {
        e.target.value = e.target.value.replace(/\D/, "");
        this.setState({
            patientExistLoaded: false,
            openPatientSearchListPopup: false,
            mobile_search: e.target.value
        }, () => this.mobileNumSearchFun(this.state.mobile_search, this.state.hospital_id, this.state.isd_code));
    }

    handleISDDropdownChange = e => {
        this.setState({
            isd_code: e.target.value,
            openPatientSearchListPopup: false
        }, () => this.mobileNumSearchFun(this.state.mobile_search, this.state.hospital_id, this.state.isd_code));
    }

    mobileNumSearchFun(mobile_no, id, isd_code) {
        const Authorization = LS_SERVICE.get('authorization');
        if (this.state.mobile_search.length > 6) {
            axios.get(PATIENT_SEARCH({ mobile_no, id, isd_code }), {
                dataType: "json",
                headers: {
                    'Authorization': Authorization
                },
            }).then(response => {
                let resData = response.data;
                if (resData.flag) {
                    this.setState({
                        patientExistLoaded: true,
                        patientExist: resData.flag,
                        patientExistData: resData.search,
                        openPatientSearchListPopup: true
                    })
                } else {
                    this.setState({
                        patientExistLoaded: true,
                        patientExist: resData.flag
                    })
                }
            }).catch(error => {
                this.setState({
                    patientExistLoaded: true
                })
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
        }
    }

    handleCalendarSelectAllow = (data) => {
        let slotsArr = [];
        if (LS_SERVICE.has('slots_array')) {
            slotsArr = LS_SERVICE.get('slots_array');
            slotsArr = slotsArr.filter(e => e.date === data.startStr);
            if (slotsArr.length > 0) {
                return true;
            }
        }
        return false;
    }

    handlCalendarSelect = (data) => {
        this.setState({
            startTime: data.startStr
        }, () => this.handleCalendarDateClick(this.state.startTime))
    }

    handlePatientDetailRadio = (value) => {
        this.setState({
            newUserFlag: value,
            mobile_search: '',
            mobile_number: '',
            first_name: '',
            last_name: '',
        })
    }
    handleClickNav(booking_id, booking_status_code) {
        this.props.history.push({
            pathname: "/doctor-consult",
            state: { booking_id: booking_id, booking_status_code: booking_status_code },
        });
    }

    handleCardHeadClick = (e, data) => {

        const elem = e.currentTarget;

        if (!elem.nextElementSibling.classList.contains('show')) {

            let { isPatientTabActive, hospital_list_date, hospital_id } = this.state;

            hospital_id = data.id;

            let dataNew = {}

            dataNew.date = hospital_list_date;

            if (isPatientTabActive) {
                this.setState({
                    hospital_id,
                    hospital_data_loaded: false,
                    appointment_page: 1
                }, () => this.appointmentList(dataNew))
            } else {
                this.setState({
                    hospital_id,
                    hospital_data_loaded: false,
                }, () => this.getAppointmentSlotsList(hospital_list_date))
            }
        }
    }

    render() {

        const {
            isPatientTabActive,
            isBookingTabActive,
            hospital_list_data_loaded,
            hospital_data_loaded,
            isRequestProcessing } = this.state;

        return (
            <>
                <HeaderComponent />
                <SubHeaderComponent
                    pageTitle={'My Calendar'}
                />
                <Container className="mt-2 tabs-healtvc custom-container">
                    <Row>
                        <Col md={7}>
                            <div className="my-calendar" style={{ height: '550px' }}>
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    header={{
                                        left: "prev",
                                        center: "title",
                                        right: "next"
                                    }}
                                    height={'parent'}
                                    events={
                                        (fetchInfo, successCallback, failureCallback) => this.getCalendarData(fetchInfo, successCallback, failureCallback)
                                    }
                                    showNonCurrentDates={false}
                                    selectable={true}
                                    selectLongPressDelay={50}
                                    selectAllow={this.handleCalendarSelectAllow}
                                    select={this.handlCalendarSelect}
                                />
                            </div>
                        </Col>
                        <Col md={5} className="tabs-healthvccl pl-0" style={{ height: '550px' }}>
                            <Tab.Container id="tabs-healthvccont" defaultActiveKey="patients">
                                <Row>
                                    <Col md={10} className="offset-1">
                                        <div className="mb-4 nav nav-pills nav-justified nav-tabscal" role="tablist">
                                            <div className="nav-item">
                                                <a href="!#" role="tab" data-rb-event-key="patients" id="tabs-healthvccont-tab-patients" aria-controls="tabs-healthvccont-tabpane-patients" aria-selected="true"
                                                    className={`${
                                                        isPatientTabActive ? "active" : ""
                                                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                                                    onClick={(e) =>
                                                        this.handleTabClick(e, TAB_STATUS.PATIENT)
                                                    }
                                                >
                                                    Patients
                                                </a>
                                            </div>
                                            <div className="nav-item">
                                                <a href="!#" role="tab" data-rb-event-key="book-slots" id="tabs-healthvccont-tab-book-slots" aria-controls="tabs-healthvccont-tabpane-book-slots" aria-selected="false"
                                                    className={`${
                                                        isBookingTabActive ? "active" : ""
                                                        } h4 m-0 border rounded-0 d-flex align-items-center justify-content-center nav-link`}
                                                    onClick={(e) =>
                                                        this.handleTabClick(e, TAB_STATUS.BOOKING)
                                                    }
                                                >
                                                    Book Slots
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey="patients">
                                        <Accordion defaultActiveKey={this.state.hospital_key}>
                                            <Card>
                                                {hospital_list_data_loaded && this.state.hospital_list.length > 0 ? (
                                                    this.state.hospital_list.map((e, idx) => (
                                                        <span key={idx}>
                                                            <Accordion.Toggle as={Card.Header} eventKey={idx} onClick={el => this.handleCardHeadClick(el, e)}>
                                                                <div className="calendar-cardheader">
                                                                    <Card.Title className="mb-1">{e.name}</Card.Title>
                                                                    <Card.Text className="m-0 text-muted">
                                                                        {(e.start_time && e.end_time) !== null ? (
                                                                            (e.start_time.toString().split('')[0] !== '0' ? e.start_time : e.start_time.toString().substring(1)) + " to " +
                                                                            (e.end_time.toString().split('')[0] !== '0' ? e.end_time : e.end_time.toString().substring(1))
                                                                        ) : ""}
                                                                    </Card.Text>
                                                                    <Card.Subtitle className="healthvcst-count">{e.appointment_count}/{e.total_slots}</Card.Subtitle>
                                                                    <i className="healthvc-droparr"></i>
                                                                </div>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={idx} className={`accordion_collapse_scroll scroll_loader`}>
                                                                <div>
                                                                    <div
                                                                        style={{
                                                                            display: `${hospital_data_loaded ? "block" : "none"}`,
                                                                        }}>
                                                                        {this.state.isPatientTabActive ? (this.state.hospital_appointments_data.length > 0 ? (
                                                                            this.state.hospital_appointments_data.map((e, i) => (
                                                                                <Card.Body className="border-bottom p-3" key={i}
                                                                                    onClick={() => e.booking_status_code !== 3 ? this.handleClickNav(e.id, e.booking_status_code) : ''}
                                                                                    style={{ cursor: e.booking_status_code !== 3 ? 'pointer' : 'default' }}
                                                                                >
                                                                                    <Row>
                                                                                        <Col className="d-flex align-items-center">
                                                                                            <Card.Title className="m-0 h6">{e.patient.first_name.toLowerCase()}{" "}{e.patient.last_name ? e.patient.last_name.toLowerCase() : ''}</Card.Title>
                                                                                            {/* <Card.Text className="m-0 text-muted h6">{UHID(e.patient.uhid)}</Card.Text> */}
                                                                                        </Col>
                                                                                        <Col className="d-flex justify-content-center align-items-center">
                                                                                            <Card.Text className="m-0 h6 text-black">{this.bookingTime(e.booking_time)}</Card.Text>
                                                                                        </Col>
                                                                                        <Col className="d-flex justify-content-end align-items-center">
                                                                                            <Card.Text className={`m-0 text-${e.booking_status.toLowerCase()}`}>{e.booking_status === 'Arrived' ? 'Completed' : (e.booking_status === 'Canceled' ? 'Cancelled' : e.booking_status)}</Card.Text>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card.Body>

                                                                            ))
                                                                        ) : hospital_data_loaded && this.state.hospital_appointments_data.length === 0 ? (
                                                                            <Card.Body className="border-bottom p-3" key="1">
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Card.Title className="mb-0 text-center">No Appointments</Card.Title>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        ) : '') : (
                                                                                <Card.Body className="pt-0">
                                                                                    <Row className="pt-2 cb-stickyheader">
                                                                                        <Col>
                                                                                            <span className="mr-3 d-inline-flex align-items-center">
                                                                                                <Badge className="mr-1" style={{ background: '#126dcb' }}>&nbsp;&nbsp;&nbsp;</Badge> Available
                                                                                            </span>

                                                                                            <span className="mr-3 d-inline-flex align-items-center">
                                                                                                <Badge className="mr-1" style={{ background: '#08b810' }}>&nbsp;&nbsp;&nbsp;</Badge> Booked
                                                                                            </span>

                                                                                            <span className="mr-3 d-inline-flex align-items-center">
                                                                                                <Badge className="mr-1" style={{ background: '#fa6666' }}>&nbsp;&nbsp;&nbsp;</Badge> Blocked
                                                                                            </span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="mt-2">
                                                                                        {this.state.hospital_slots_data.length > 0 && this.state.hospital_slots_data[0] !== 'invalid' ? (
                                                                                            <Col className="slots-btn">
                                                                                                {this.state.hospital_slots_data.map((e, i) => (
                                                                                                    <div key={i}>
                                                                                                        <Button size="sm" variant="outline-primary"
                                                                                                            className={`button-${e.status} button-available`}
                                                                                                            data-id={e.id} data-slot={e.slot} data-fees={e.fees}
                                                                                                            onClick={(e) => this.handleBookingButClick(e, i)}
                                                                                                        >{e.slotTime[1].split('')[0] !== '0' ? e.slotTime[1] : e.slotTime[1].substring(1)}{" "}{e.slotTime[2]}</Button>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </Col>
                                                                                        ) : this.state.hospital_slots_data.length > 0 && this.state.hospital_slots_data[0] === 'invalid' ? (
                                                                                            <Col>
                                                                                                <Card.Title className="mb-0 text-center mt-3"><p className="h5 no-appointement">No slot available for booking</p></Card.Title>
                                                                                            </Col>
                                                                                        ) : hospital_data_loaded && this.state.hospital_slots_data.length === 0 ? (
                                                                                            <Col>
                                                                                                <Card.Title className="mb-0 text-center">NO SLOTS</Card.Title>
                                                                                            </Col>
                                                                                        ) : ''}
                                                                                    </Row>
                                                                                </Card.Body>)}
                                                                    </div>
                                                                    <div
                                                                        className="justify-content-center align-self-center mt-2"
                                                                        style={{
                                                                            display: `${
                                                                                !hospital_data_loaded || isRequestProcessing ? "flex" : "none"
                                                                                }`,
                                                                        }}
                                                                    >
                                                                        {LOADER_RED}
                                                                    </div>
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </span>
                                                    ))
                                                ) : hospital_list_data_loaded || hospital_data_loaded ? (
                                                    <span className="p-3">
                                                        No Appointments and Slots Today
                                                    </span>
                                                ) : (
                                                    <div
                                                        className="justify-content-center align-self-center mt-2"
                                                        style={{
                                                            display: `${
                                                                !hospital_data_loaded ? "flex" : "none"
                                                                }`,
                                                        }}
                                                    >
                                                        {LOADER_RED}
                                                    </div>
                                                )}
                                            </Card>
                                        </Accordion>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>

                <Popup
                    className={`${!this.state.newUserFlag ? 'cancel-popbig' : ''} cancel-pop`}
                    open={this.state.openBookPopup}
                    modal
                >
                    <div className='popup'>
                        <div className="close" onClick={() => this.handlePopupOpenClose(false)}>
                            &times;
                        </div>
                        <div className='container'>
                            <div className='row mt-2 title-pl'>
                                <div className="col">
                                    <p className="m-0">Book Appointment</p>
                                </div>
                            </div>
                            <div className="select-patient-form radio-tabs emrfrm">

                                <div className="row  my-3">
                                    <div className="col-12">
                                        <p className="page-title d-md-inline mr-3 patient-details">Patient Details</p>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="row">
                                        <div className="col-2 mr-0">
                                            <select id="dropdownCountry" value={this.state.isd_code} onChange={this.handleISDDropdownChange}>
                                                {this.state.isd_code_list.length > 0 ? this.state.isd_code_list.map((e, i) => (
                                                    <option key={i} value={e.isd_code}>+{e.isd_code}</option>
                                                )) : ""}
                                            </select>
                                        </div>
                                        <div className="col-10 ml-0 pl-0">
                                            <div className="form-group">
                                                <input type="text" className="input-group form-control mobilenumbers" id="existingphone" name="existingphone" maxLength="10"
                                                    data-alert="Please fill the mobile number" placeholder="Phone" autoComplete="off"
                                                    value={this.state.mobile_search}
                                                    onChange={this.mobleNumSearch}
                                                    onFocus={(e) => JQUERYLABELS(e.target)}
                                                    onClick={(e) => JQUERYLABELS(e.target)} />
                                                <label htmlFor="existingphone">Mobile No<span>*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="existing_patients">
                                                {this.state.patientExistLoaded && this.state.mobile_search.length === 10 ? (
                                                    !this.state.patientExist ? 'Patient Doesn\'t Exist' : ''
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.openPatientSearchListPopup ?
                            (<div className=' registered-patient'>
                                <div className='container-fluid'>
                                    <div className='patient-details'>
                                        <p>Patients registered with this mobile number:</p>
                                    </div>
                                    <div className="patient-datacontainer">
                                        {this.state.patientExistData.length > 0 ? (
                                            this.state.patientExistData.map((e, i) => (<div className='row' key={i}>
                                                <div className='col-md-6'>
                                                    <label className="radio patient-name">{(e.first_name + ' ' + e.last_name).toLowerCase()}
                                                        <input type="radio" name="is_company"
                                                            data-first_name={e.first_name} data-last_name={e.last_name}
                                                            data-mobile_no={e.mobile_no} id={'patient_' + e.id} value={e.id}
                                                            onClick={this.handSearchExistPatients} />
                                                        <span className="checkround"></span>
                                                    </label>
                                                </div>
                                                <div className='col-md-6 patient-num'>
                                                    <label htmlFor={'patient_' + e.id} className='patient-num'>
                                                        <p className="m-0">+{e.isd_code}-<span>{e.mobile_no}</span></p>
                                                    </label>
                                                </div>
                                            </div>)
                                            )) : ''}
                                    </div>
                                </div>
                            </div>) : ''}
                    </div>
                </Popup>

                <Popup
                    className="cancel-pop"
                    open={this.state.openRegisterConfirmPopup}
                    onClose={() => this.handleRegisterConfirmPopup(false)}
                    modal
                >
                    <div className='popup text-center'>
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleRegisterConfirmPopup(false)}>
                            &times;
                        </div>
                        <div className='container-fluid'>
                            <div className='row mt-2 ml-2 title'>
                                <p>Appointment Confirmation</p>
                            </div>
                            <div className='calendar-icon '>
                                <img className='cal' src={calendar} alt="Calendar Icon"></img>
                            </div>
                            <div className='appointement-details mt-4'>
                                <p>Hi {LS_SERVICE.get("doctor").name}
                                    <br />You have just booked an appointment for
                                    <br /><span style={{ color: '#d24350', fontWeight: 'bolder' }}>{this.state.patient_name}</span>
                                    <br />at {LS_SERVICE.has('slotData') ? LS_SERVICE.get('slotData').slot.split(' ')[1] + ' ' + LS_SERVICE.get('slotData').slot.split(' ')[2] : ''}
                                    <br />{new Date(this.state.booking_date).toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                                </p>
                            </div>

                        </div>
                        <div className="mb-3 btns">
                            <button className="btn btn-outline-dark cancel-btn mr-2" onClick={() => this.handleRegisterConfirmPopup(false)}>CANCEL</button>
                            <button className="btn btn-danger reschedule" onClick={this.registePatientConfirm}>
                                CONFIRM
                                <span className="loader-span">{LOADER}</span>
                            </button>
                        </div>
                    </div>
                </Popup>

                {/* <Popup
                    className="cancel-pop"
                    open={this.state.openPatientSearchListPopup}
                    onClose={() => this.handlePatientSearchListPopup(false)}
                    modal
                >
                    <div className='popup' >
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handlePatientSearchListPopup(false)}>
                            &times;
                    </div>
                        <div className='container-fluid'>
                            <div className='row mt-2 ml-1 title-pl'>
                                <p>Registered Patient</p>
                            </div>
                            {this.state.patientExistData.length > 0 ? (
                                this.state.patientExistData.map((e, i) => (<div className='row' key={i}>
                                    <div className='col-md-6'>
                                        <label className="radio patient-name">{(e.first_name + ' ' + e.last_name).toLowerCase()}
                                            <input type="radio" name="is_company"
                                                data-first_name={e.first_name} data-last_name={e.last_name}
                                                data-mobile_no={e.mobile_no} id={'patient_' + e.id} value={e.id}
                                                onClick={this.handSearchExistPatients} />
                                            <span className="checkround"></span>
                                        </label>
                                    </div>
                                    <div className='col-md-6 patient-num'>
                                        <label htmlFor={'patient_' + e.id} className='patient-num'>
                                            <p className="m-0">+91-<span>{e.mobile_no}</span></p>
                                        </label>
                                    </div>
                                </div>)
                                )) : ''}
                        </div>
                    </div>
                </Popup> */}

                <Popup
                    className="cancel-pop"
                    open={this.state.openRegisterIsMissedPopup}
                    onClose={() => this.handleRegisterIsMissedPopup(false)}
                    modal
                >
                    <div className='popup text-center' >
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleRegisterIsMissedPopup(false)}>
                            &times;
                        </div>
                        <div className='container-fluid'>
                            <div className='row mt-2 ml-2 title'>
                                <p>Appointment Confirmation</p>
                            </div>
                            <div className='calendar-icon '>
                                <img className='cal' src={calendar} alt="Calendar Icon"></img>
                            </div>
                            <div className='appointement-details mt-4'>
                                <p>Hi {LS_SERVICE.get("doctor").name}
                                    <br />You are trying to book an appointment for Missed Slot
                                    <br />Kindly Choose a slot a hour ahead
                                </p>
                            </div>

                        </div>
                        <div className="mb-3 btns">
                            <button className="btn btn-outline-dark cancel-btn" onClick={() => this.handleRegisterIsMissedPopup(false)}>CANCEL</button>
                        </div>
                    </div>
                </Popup>

                <ToastComponent
                    successClass={this.state.toastSuccessClass}
                    show={this.state.toast}
                    message={this.state.toastmsg}
                />
            </>
        );
    }
}

export default MyCalendarComponent;