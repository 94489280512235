import React, { Component } from "react";
import logo from "../../assets/images/logo-myhealth.png";
import axios from "axios";
import OtpInput from "react-otp-input";
import { OTP_API, LOGIN_API, LOADER, HOSPITAL_LIST_API, } from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import "./otp.css";
import ToastComponent from "../toast/toast";

class Otp extends Component {

  state = {
    uid: LS_SERVICE.get("uid"),
    otp: "",
    is_pin_available: LS_SERVICE.get('is_pin_available'),
    is_forgot_pin: LS_SERVICE.get('is_forgot_pin'),
    otpSubmitDisabled: true,
    resendOtpDisabled: false,
    spinner: false,
    isAuthenticated: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
  };

  constructor(props) {
    super(props);
    if (!LS_SERVICE.get("uid"))
      props.history.push("/");

    this.resendOTPHandler(null, true);
  }

  handleOTPChange = (otpVal) => {
    let otp = { ...this.state.otp };
    otp = otpVal.replace(/\D/, "");

    if (otp.length === 4) {
      this.setState({
        otpSubmitDisabled: false,
      });
    } else {
      this.setState({
        otpSubmitDisabled: true,
      });
    }

    this.setState({ otp });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  resendOTPHandler = (e, onLoad = false) => {
    if (!onLoad) {
      e.preventDefault();
      e.stopPropagation();
      this.resetToastState();
      this.setState({
        resendOtpDisabled: true
      })
    }
    const uid = LS_SERVICE.get("uid");
    axios
      .post(LOGIN_API, { uid })
      .then((response) => {
        this.setState({
          resendOtpDisabled: false
        })
        const data = response.data;
        LS_SERVICE.set("uid", uid);
        if (data.message.length > 0)
          this.setState({
            toastSuccessClass: "bg-success",
            toast: true,
            toastmsg: data.message
          });
      })
      .catch((error) => {
        this.setState({
          resendOtpDisabled: false
        })
        error = error.response;
        if (error === undefined) return;
        if (error.status === 400) {
          if (error && error.data && error.data.errors.length > 0) {
            this.setState({
              toast: true,
              toastmsg: error.data.errors[0].message,
            });
          }
        }
      });
  };

  resetToastState = () => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    });
  };

  submitHandler = (e) => {
    this.resetToastState();
    e.preventDefault();
    e.stopPropagation();
    const { otp, uid } = this.state;
    this.setState({
      otpSubmitDisabled: true,
      spinner: true
    })
    axios
      .post(OTP_API, { otp, uid })
      .then((Response) => {
        this.setState({
          otpSubmitDisabled: false,
          spinner: false
        })
        LS_SERVICE.set('is_forgot_pin', false);
        if (this.state.is_pin_available) {
          const data = Response.data;
          const headers = Response.headers;

          const doctor = data.doctor[0];
          const authorization = headers.authorization;
          this.setState({
            isAuthenticated: true,
          });
          LS_SERVICE.set("doctor", doctor);
          LS_SERVICE.set("authorization", authorization);
          LS_SERVICE.set("is_authenticated", this.state.isAuthenticated);

          //FECTHING HOSPITAL LIST
          axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
          axios.get(HOSPITAL_LIST_API)
            .then((Response) => {
              LS_SERVICE.set("hospitals", Response.data.hospitals);
              this.props.history.push("/patient-lists");
            })
            .catch((error) => {
              console.log(error);
              if (error && error.response && error.response.status === 401) {
                LS_SERVICE.clear();
                this.props.history.push("/");
              }
            });
        } else {
          this.props.history.push('/pin');
        }
      })
      .catch((error) => {
        this.setState({
          otpSubmitDisabled: false,
          spinner: false
        })
        LS_SERVICE.set("is_authenticated", this.state.isAuthenticated);
        error = error.response;
        if (error && error.status === 400) {
          if (error && error.data && error.data.errors.length > 0) {
            this.setState({
              toast: true,
              toastmsg: error.data.errors[0].message,
            });
          }
        }
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center full-height">
          <div className="col-auto d-flex align-self-center">
            <div className="text-center d-flex align-items-center">
              <form className="otp-form" onSubmit={this.submitHandler}>
                <img className="img-fluid otp-logo" src={logo} alt="logo" />
                {/* <div className="otp-virtual-name pt-3">
                  <p>VIRTUAL CONSULT PLATFORM FOR DOCTORS</p>
                </div> */}
                <h5 className="virtual-heading otp-virtual-name mt-4">
                  {" "}
                  VIRTUAL CONSULT PLATFORM FOR DOCTORS
                </h5>
                <p className="text-center mt-5 otp-text bold-text">
                  Enter the OTP received on the registered mobile number
                </p>
                <div className="form-group pt-5">
                  <div className="otpinp_wrapper">
                    <OtpInput
                      onChange={this.handleOTPChange}
                      shouldAutoFocus={true}
                      hasErrored={true}
                      numInputs={4}
                      isInputNum={false}
                      value={this.state.otp}
                    />
                  </div>
                </div>
                <div className="form-group mb-0">
                  <button
                    className="btn btn-danger btn-submit otp-btn px-5 mt-5"
                    disabled={this.state.otpSubmitDisabled}
                  >
                    SUBMIT{this.state.spinner ? LOADER : ""}
                  </button>
                  <p className="pt-3 m-0">
                    <a
                      className={`${this.state.resendOtpDisabled ? 'disabled' : ''} Resend-otp`}
                      href="!#"
                      onClick={this.resendOTPHandler}
                    >
                      {/* Resend OTP */}
                      {this.state.resendOtpDisabled ? 'Sending...' : "Resend OTP"}
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </div>
    );
  }
}

export default Otp;
