import * as React from 'react';
import { withRouter } from "react-router-dom";
import FloatingVc from './floating-vc';
import VonageFloatingVc from './vonage-floating-vc';
import { callDriverService } from '../../utils/data-sharing';

class FloaterWindow extends React.Component {

    state = {
        driver: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.subscription = callDriverService.status().subscribe(result => {
            if (result.driver) {
                this.setState({
                    driver: result.driver
                });
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        return(
            <>
                {this.state.driver == 'quickblox' ? (
                    <FloatingVc />
                ) : null}
                {this.state.driver == 'vonage' ? (
                    <VonageFloatingVc />
                ) : null}
            </>
        )
    }

}

export default withRouter(FloaterWindow);
