/**
 *  Start Consult Container.
 *
 *  @author Arifa Arifin Ahmed <arifa@innocirc.com>
 *  @version 1.0.0 (24-May-2019)
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 *
 *  @description
 *  Start Consult Conatiner for VC Desk App with Routing.
 *
 */

import React from "react";
import VideoIcon from "../../assets/images/electronics_red.svg";
import VIconWhite from "../../assets/images/electronics_white.svg";
import AudioIcon from "../../assets/images/phone_consult_gray.svg"
import AudioIconWhite from "../../assets/images/phone_consult_white.svg";
import "../../assets/styles/style.css";
import jpg from "../../assets/images/jpeg.png";
import pdf from "../../assets/images/pdf.png";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import $ from 'jquery';
import Popup from "reactjs-popup";
import HeaderComponent from "../../components/header/headerComponent";
import SubHeaderComponent from "../../components/sub-header/subHeaderComponent";
import VideoConferencing from "../../components/video-conferencing/VideoConferencing";
import QbVideoConferencing from "../../components/video-conferencing/QbVideoConferencing";
import axios from "axios";
import Moment from "react-moment";
import {
  APPOINTMENT_DETAILS,
  COMPLETE_APPOINTMENT,
  SEPARATEUPLOADEDDOCS,
  GETGENDER,
  GETDOB,
  API_BASE_URL2,
  API_BASE_URL3,
  LOADER_RED,
  EXOTEL_URL,
  LOADER,
  LOADER_BLACK,
  BOOKINGTIME24TO12,
  HANDLEIMAGEROTATE,
  IMAGEPREVNEXTRESET,
  GETURLEXTENSION,
  CALL_RATING
} from "../../utils/constant";
import ToastComponent from "../toast/toast";
import LS_SERVICE from "../../utils/localStorage";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button, Input } from "reactstrap";
import QbHelpers from "../../helpers/QbHelpers";
import { startStreaming, stopStreaming } from "../../utils/audioUtils";
import { getTranscriptFromJSON, clientSocketDisconnect } from "../../utils/socketAapi";
import BeautyStars from 'beauty-stars';

import { messageService, callService } from '../../utils/data-sharing';
import Bandwidth from '../../components/bandwidth/bandwidth';

let ENDCALL = "";
let PastPrescriptionInfinitePagination = "";
let medicine_data_set_flag = 0;
let diagnosis_data_set_flag = 0;
let AUDIOCONTEXT = "";
let localStorageDiagnosisNote = "";
let localStorageMedicinesTests = "";

class DocComp extends React.Component {
  state = {
    callPatientBtn: true, // true means call btn disabled
    callNotificationMsg: "",
    patient: {
      name: '',
      number: null,
      id: null,
      dob: '',
      uhid: null,
      gender: '',
      booking_time: null,
    },
    appointment_status: this.props.location.state !== undefined ? this.props.location.state.booking_status_code : this.props.history.push('/patient-list'),
    appointment_details: [],
    consult_type: 1,
    uploads: [],
    past_prescriptions: [],
    past_prescription_page: 1,
    total_prescription_pages: 1,
    noMorePastPrescriptionData: false,
    isPastPrescriptionRequestProcessing: false,
    pp_dataLoaded: false,
    diagnosis_notes: "",
    medicines_tests: "",
    update_prescription_text: "",
    modal: false,
    dataFile: "",
    modalClass: "modal-imgdocs",
    documentIndex: 0,
    documentCount: 0,
    documentType: "",
    allDocuments: [],
    canEndCall: false,
    hungupExistingCall: false,
    completeAppointmentBtnValid: true,
    updatePrescriptionBtnValid: true,
    openhandleCompleteAppointmentButtonPop: false,
    openhandleCompleteAppointmentButtonPopLoader: false,
    openAudioCallPop: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    QBEndCall: true,
    handleCallFeedbackPopup: false,
    doctor_rating: 5,
    doctor_remarks: "",
    audioMute: false,
    started: false,
    startedDianosis: false,
    startedMedicine: false,
    reset: 0,
    restartTime: '295000',
    counterStart: null,
    timestamp: '00:00:00',
    multiline: 'Controlled',
    outputText: '',
    newText: '',
    concatText: '',
    isFinal: false,
    isLastNotFinal: '',
    resetCount: 0,
    transcriptList: [],
    diagnosisNotesList: [],
    medicinesTestsList: [],
    diagnosisNotesListFinal: [],
    medicinesTestsListFinal: [],
    transcriptCounter: 0,
    transcriptCounter1: 0,
    transcriptObject: {},
    restartCounter: 1,
    diagnosisBeforeAppendFlag: 1,
    medicneBeforeAppendFlag: 1,
    disableTextFields: false,
    diagTextActive: false,
    mediTextActive: false,
    callInitialized: false,
    video_call_driver: null // 1 for QB, 2 for Vonage
  };

  constructor(props) {
    super(props);
    this.receiveInParent = this.receiveInParent.bind(this);
    this.updatePage = this.updatePage.bind(this);
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    axios
      .get(
        APPOINTMENT_DETAILS +
        "booking_id=" +
        this.props.location.state.booking_id
      )
      .then((Response) => {
        let name = `${Response.data.appointment.patient.first_name} ${
          Response.data.appointment.patient.last_name !== null
            ? Response.data.appointment.patient.last_name
            : ""
          }`;
        let formData = [];
        if (LS_SERVICE.has('formData')) {
          formData = LS_SERVICE.get('formData');
        }
        let diagnosis_notes = '';
        let medicines_tests = '';
        let update_prescription_text = '';
        if (formData.find(e => e.id === Response.data.appointment.id) !== undefined) {
          formData.forEach(e => {
            if (e.id === Response.data.appointment.id) {
              diagnosis_notes = e['diagnosis_notes'];
              medicines_tests = e['medicines_tests'];
              update_prescription_text = e['update_prescription_text'] !== undefined ? e['update_prescription_text'] : "";
            }
          })
        }
        this.setState({
          appointment_status: Response.data.appointment.booking_status_code,
          appointment_details: Response.data.appointment,
          consult_type: Response.data.appointment.consult_type,
          uploads: SEPARATEUPLOADEDDOCS(
            Response.data.appointment.patient_docments
          ),
          diagnosis_notes: diagnosis_notes !== '' ? diagnosis_notes : Response.data.appointment.diagnosis_notes ?? "",
          medicines_tests: medicines_tests !== '' ? medicines_tests : Response.data.appointment.medicines_tests ?? "",
          update_prescription_text: update_prescription_text !== '' ? update_prescription_text : Response.data.appointment.update_prescription ?? "",
          patient: {
            name: name,
            number: Response.data.appointment.patient.mobile_no,
            id: Response.data.appointment.patient.id,
            dob: GETDOB(Response.data.appointment.patient.dob),
            uhid: Response.data.appointment.patient.uhid,
            booking_time: Response.data.appointment.start_time,
            gender: GETGENDER(Response.data.appointment.patient.gender),
          },
          video_call_driver: Response.data.appointment.is_vc_call || null
        }, () => {
          localStorageDiagnosisNote = this.state.diagnosis_notes;
          localStorageMedicinesTests = this.state.medicines_tests;
          this.pastPresciptions();
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });

    getTranscriptFromJSON((err, transcriptObject) => {
      if (diagnosis_data_set_flag === 1) {
        let { diagnosis_notes } = this.state;
        if (!transcriptObject.isFinal) {
          diagnosis_notes = this.sentenceCase(localStorageDiagnosisNote + " " + transcriptObject.transcript)
          this.setState({
            diagnosis_notes
          }, () => this.diagnosisNotesRef.current.scrollTop = this.diagnosisNotesRef.current.scrollHeight)
        }

        if (transcriptObject.isFinal) {
          diagnosis_notes = this.sentenceCase(localStorageDiagnosisNote + " " + transcriptObject.transcript)
          this.setState({
            diagnosis_notes,
          }, () => {
            localStorageDiagnosisNote = this.state.diagnosis_notes;
            this.diagnosisNotesRef.current.scrollTop = this.diagnosisNotesRef.current.scrollHeight;
          }
          );
        }
      }

      if (medicine_data_set_flag === 1) {
        let { medicines_tests } = this.state;
        if (!transcriptObject.isFinal) {
          medicines_tests = this.sentenceCase(localStorageMedicinesTests + " " + transcriptObject.transcript);
          this.setState({
            medicines_tests
          }, () => this.medicineNotesRef.current.scrollTop = this.medicineNotesRef.current.scrollHeight);
        }
        if (transcriptObject.isFinal) {
          medicines_tests = this.sentenceCase(localStorageMedicinesTests + " " + transcriptObject.transcript);
          this.setState({
            medicines_tests
          }, () => {
            localStorageMedicinesTests = this.state.medicines_tests;
            this.medicineNotesRef.current.scrollTop = this.medicineNotesRef.current.scrollHeight;
          });
        }
      }

      console.log(transcriptObject);
    });

    this.handleInputChange = this.handleInputChange.bind(this);
    this.diagnosisNotesRef = React.createRef();
    this.medicineNotesRef = React.createRef();

  }

  updatePage() {
    console.log('OUTOUTOUT');
    this.forceUpdate();
  }

  componentDidMount() {
    this.addScrollEvent();
    const frmData = LS_SERVICE.get('formData');
    if (!frmData) {
      LS_SERVICE.set('formData', []);
    }

    this.subscription = messageService.getMessage().subscribe(message => {
      if (message && message.update) {
        window.location.reload(true);
      }
    });
  }

  componentWillUnmount() {
    this.cleanupListener();
    if (this.state.audio) {
      this.stopListening();
    }
    console.log('>>> componentWillUnmount has been called');
    this.subscription.unsubscribe();
  }

  addScrollEvent = () => {
    PastPrescriptionInfinitePagination = document.querySelector(".scroll_loader");
    if (PastPrescriptionInfinitePagination !== null)
      PastPrescriptionInfinitePagination.addEventListener("scroll", this.handleScroll, true);
  };

  cleanupListener = () => {
    PastPrescriptionInfinitePagination.removeEventListener("scroll", this.handleScroll, true);
  };

  checkScrollSpaceAvailability = () => {
    if (this.state.past_prescription_page !== 1) return;
    const lastLi = document.querySelector(
      ".scroll_loader .past-prescriptionlist  > li:last-child"
    );
    const scrollWin = document.querySelector(".scroll_loader");
    if (lastLi === null) return;
    const listLiTotalHeight = lastLi.clientHeight * document.querySelectorAll(".scroll_loader .past-prescriptionlist li").length;
    const pageOffset = scrollWin.scrollTop + scrollWin.clientHeight;
    if (scrollWin.scrollTop === 0) {
      if (pageOffset > listLiTotalHeight) {
        this.loadMore();
      }
    }
  }

  handleScroll = () => {
    const lastLi = document.querySelector(
      ".scroll_loader .past-prescriptionlist  > li:last-child"
    );
    const scrollWin = document.querySelector(".scroll_loader");
    if (lastLi === null) return;
    var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
    if (pageOffset > lastLiOffset) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { noMorePastPrescriptionData, isPastPrescriptionRequestProcessing } = this.state;

    if (noMorePastPrescriptionData) return;
    if (isPastPrescriptionRequestProcessing === true) return;

    this.setState({
      isPastPrescriptionRequestProcessing: true,
    });

    this.setState(
      (prevState) => ({
        past_prescription_page: prevState.past_prescription_page + 1
      }),
      () => {
        this.pastPresciptions()
      }
    );
  };

  pastPresciptions = () => {

    const { past_prescription_page } = this.state;

    axios
      .get(
        API_BASE_URL3 +
        "patient-past-prescription?patient_id=" +
        this.state.patient.id +
        "&page=" + past_prescription_page
      )
      .then((response) => {
        const dataNew = response.data.reports;
        const tPages = response.data.pagination.total_pages;
        const noData = response.data.pagination.links.next === "" ? true : false;
        const {
          past_prescriptions
        } = this.state;

        this.setState({
          past_prescriptions: [...past_prescriptions, ...dataNew],
          pp_dataLoaded: true,
          total_prescription_pages: tPages,
          isPastPrescriptionRequestProcessing: false,
          noMorePastPrescriptionData: noData,
        }, () => this.checkScrollSpaceAvailability());
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          pp_dataLoaded: true,
        });
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  documentToggle = (e, files, isDoc = false) => {
    if (files !== undefined) {
      if (isDoc) {
        this.setState({
          modalClass: "modal-imgdocs modal-docs",
          documentType: 'application/pdf',
        });
      } else {
        this.setState({
          modalClass: "modal-imgdocs",
          documentType: '',
        });
      }
      if (e !== null) {
        e.preventDefault();
        this.setState({
          documentIndex: $(e.currentTarget).index(),
          documentCount: $(e.currentTarget).siblings().length
        }, () => {
          this.setState({
            showArrow: this.state.documentCount > 1 ? true : false,
          })
        })

        this.setState({
          allDocuments: files,
          dataFile: files[$(e.currentTarget).index()],
        });
      } else {
        this.setState({
          dataFile: files,
        })
      }
    }

    this.setState({
      modal: !this.state.modal,
    })
  };

  callUser() {
    callService.init({ start: true });
    // this.handleCallRatings(null, { status: 0 });
    // this.handleCallRatings(null, { status: 0, callStatus: () => callService.init({ start: true }) });
  }

  endCall() {
    callService.init({ end: true });
    // this.handleCallRatings(null, { status: 1 });
  }

  receiveInParent(callInfo) {
    if (typeof callInfo !== "undefined") {
      this.setState(
        {
          callPatientBtn: callInfo.callPatientBtn,
          callNotificationMsg: callInfo.callNotificationMsg,
          canEndCall: callInfo.canEndCall,
          hungupExistingCall: callInfo.hungupExistingCall || false,
        },
        () => {
          this.checkAppointmentForm();
        }
      );
    }
  }

  checkAppointmentForm() {
    if (this.state.medicines_tests.length > 0 || this.state.diagnosis_notes.length > 0) {
      this.setState({
        completeAppointmentBtnValid: false,
      });
    } else {
      this.setState({
        completeAppointmentBtnValid: true,
      });
    }
  }

  checkUpdatePrescriptionForm = () => {
    this.setState({
      updatePrescriptionBtnValid: this.state.update_prescription_text.length === 0
    })
  }

  handleInputChange(event) {

    const target = event.target;

    let frmData = {
      id: this.state.appointment_details.id,
      diagnosis_notes: this.state.diagnosis_notes,
      medicines_tests: this.state.medicines_tests,
      update_prescription_text: this.state.update_prescription_text
    }

    this.setState({ [target.name]: target.value },
      () => {
        frmData.diagnosis_notes = this.state.diagnosis_notes;
        frmData.medicines_tests = this.state.medicines_tests;
        frmData.update_prescription_text = this.state.update_prescription_text;
        localStorageDiagnosisNote = frmData.diagnosis_notes;
        localStorageMedicinesTests = frmData.medicines_tests;
        this.formDataEntry(frmData);
      });
  }

  formDataEntry(data) {

    const { id, diagnosis_notes, medicines_tests } = data;

    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    } else {
      formData = [{
        id,
        diagnosis_notes,
        medicines_tests
      }];
      LS_SERVICE.set('formData', formData)
    }

    if (formData.find((e) => e.id === this.state.appointment_details.id) !== undefined) {
      formData.forEach(e => {
        if (e.id === this.state.appointment_details.id) {
          e['diagnosis_notes'] = data.diagnosis_notes;
          e['medicines_tests'] = data.medicines_tests;
        }
      })
    } else {
      formData = [...formData, { id, diagnosis_notes, medicines_tests }]
    }
    LS_SERVICE.set('formData', formData);
    this.checkAppointmentForm();
    this.checkUpdatePrescriptionForm();
  }

  completeAppointment(e, booking_id) {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    if (ENDCALL !== "") {
      clearInterval(ENDCALL);
      ENDCALL = "";
    }

    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    }

    if (formData.find(e => e.id === booking_id) !== undefined) {
      formData = formData.filter((e) => e.id !== booking_id);
      LS_SERVICE.set('formData', formData);
    }

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    let PARAMS = {};

    if (this.state.appointment_details.prescription_bool) {
      PARAMS = {
        booking_id: booking_id,
        update_prescription: this.state.update_prescription_text
      }
    } else {
      PARAMS = {
        booking_id: booking_id,
        diagnosis_notes: this.state.diagnosis_notes,
        medicines_tests: this.state.medicines_tests,
      }
    }

    axios
      .post(COMPLETE_APPOINTMENT, PARAMS)
      .then((Response) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openhandleCompleteAppointmentButtonPop: false,
        }, () => {
          if (this.state.callInitialized) {
            this.handleBeautyPopup();
          } else {
            this.setState({
              handleCallFeedbackPopup: false,
              appointment_status: 5,
              toastSuccessClass: "bg-success",
              toast: true,
              toastmsg: "You Appointment is Completed",
            }, () => {
              if (!this.state.canEndCall) {
                this.props.history.push("/patient-lists")
              }
            })
          }
        });

      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  endCallAndCompleteAppointment(e, booking_id) {
    $(e.target).find('span.loader-span').addClass('loader-active');
    this.endCall();
    ENDCALL = setInterval(() => {
      if (!QbHelpers.isCallActive) {
        this.completeAppointment(null, booking_id);
      }
    }, 100);
  }

  openPrescriptionDoc(booking_id) {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
    axios.get(API_BASE_URL2 + "bookings/" + booking_id + "/prescriptions?mode=web")
      .then((Response) => {
        var pdfUrl = Response.data;
        this.documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleCompleteAppointmentClick = () => {
    this.setState({
      openhandleCompleteAppointmentButtonPop: true
    }, () => this.setState({
      QBEndCall: QbHelpers.isCallActive ? false : true
    }))
  }

  handleCompleteAppointmentButton = (value) => {
    this.setState({
      openhandleCompleteAppointmentButtonPop: value
    })
  }

  handleAudioCallPop = (value) => {
    this.setState({
      openAudioCallPop: value
    })
  }

  handleAUdioCallApi = (e) => {
    const elem = e.target;
    $(elem).find('span.loader-span').toggleClass('loader-active');
    const { id } = this.state.appointment_details;

    axios.post(EXOTEL_URL, { booking_id: id })
      .then((response) => {
        $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openAudioCallPop: false
        })
      })
      .catch((error) => {
        console.log(error);
        $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleNextPrev = (e) => {
    e.preventDefault();
    const { documentIndex, allDocuments, documentCount } = this.state;
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        this.setState({
          documentIndex: documentIndex - 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex - 1]),
          dataFile: allDocuments[docIndex - 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    } else {
      if (docIndex < documentCount) {
        this.setState({
          documentIndex: documentIndex + 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex + 1]),
          dataFile: allDocuments[docIndex + 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    }
  };

  resetToastState = () => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    });
  };

  handleDontRate = _ => {
    this.handleCallRatings(null, { status: 2 });
  };

  handleBeautyPopup = _ => {
    this.setState({
      handleCallFeedbackPopup: !this.state.handleCallFeedbackPopup
    });
  }

  handleCallRatings = (e, data) => {

    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').addClass('loader-active');
    }

    this.resetToastState();

    const { doctor_rating, doctor_remarks } = this.state;

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    axios
      .post(CALL_RATING, {
        booking_id: this.state.appointment_details.id,
        doctor_remarks,
        doctor_rating,
        state: data.status // 2 for feedback Submit 1 for Call end and 0 for call start
      })
      .then((Response) => {
        if (data.status === 2) {
          if (elem !== null)
            $(elem).find('span.loader-span').removeClass('loader-active');
          this.setState({
            handleCallFeedbackPopup: false,
            appointment_status: 5,
            toastSuccessClass: "bg-success",
            toast: true,
            toastmsg: "You Appointment is Completed",
          }, () => {
            if (!this.state.canEndCall) {
              this.props.history.push("/patient-lists")
            }
          })
        } else {
          this.setState({
            callInitialized: true,
            handleCallFeedbackPopup: false
          }, () => {
            // if (data.callStatus) {
            //   data.callStatus();
            // } else if (!this.state.canEndCall) {
            //   this.props.history.push("/patient-lists");
            // }
          })
        }
      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').removeClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleBeautyPopupSubmit = (e) => {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    const { doctor_rating } = this.state;

    if (doctor_rating === 0) {
      $(elem).find('span.loader-span').toggleClass('loader-active');
      $('.error-beautystars').removeClass('hidden');
      return;
    }

    this.handleCallRatings(e, { status: 2 });
  }

  setDoctorRating = (doctor_rating) => {
    this.setState({
      doctor_rating
    }, () => {
      if (this.state.doctor_rating > 0) {
        $('.error-beautystars').addClass('hidden');
      }
    })
  }

  startListening() {
    startStreaming(AUDIOCONTEXT);
    this.setState({ audio: true, isFinal: true });
  }

  dataToLocalStorage = () => {
    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    }

    if (formData.find(e => e.id === this.state.appointment_details.id) !== undefined) {
      formData.map(e => {
        if (e.id === this.state.appointment_details.id) {
          e.diagnosis_notes = this.diagnosisNotesRef.current.value;
          e.medicines_tests = this.medicineNotesRef.current.value;
        }
      })
    } else {
      formData = [...formData, {
        id: this.state.appointment_details.id,
        diagnosis_notes: this.diagnosisNotesRef.current.value,
        medicines_tests: this.medicineNotesRef.current.value,
      }]
    }
    LS_SERVICE.set('formData', formData);
  }

  stopListening() {
    clientSocketDisconnect();
    this.setState({ audio: false, started: false, startedDianosis: false, startedMedicine: false, disableTextFields: true });
    AUDIOCONTEXT.close();
    stopStreaming(AUDIOCONTEXT);
    this.setState({ disableTextFields: false, diagTextActive: false, mediTextActive: false, isFinal: false });
    this.dataToLocalStorage();
  }

  toggleListen = (e) => {
    const target = e.currentTarget;
    const buttonName = target.name;
    if (!this.state.started) {
      AUDIOCONTEXT = new (window.AudioContext || window.webkitAudioContext)();
      if (buttonName === "diagnosis_notes") {
        diagnosis_data_set_flag = 1;
        medicine_data_set_flag = 0;
        this.setState({
          started: true, startedDianosis: true, diagTextActive: true,
        });
      }
      else {
        diagnosis_data_set_flag = 0;
        medicine_data_set_flag = 1;
        this.setState({
          started: true, startedMedicine: true, mediTextActive: true,
        })
      }
    }

    if (this.state.audio) {
      this.stopListening();
    } else {
      this.startListening();
    }
  }


  sentenceCase = (input, lowercaseBefore) => {
    input = (input === undefined || input === null) ? '' : input.toString();
    if (lowercaseBefore) { input = input.toLowerCase(); }
    // let text = input.replace(/(^|\. *)([a-z])/g, (match, separator, char) => separator + char.toUpperCase())
    //   .replace(/(^|\ next line. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
    //   .replace(/(^|\ nextline. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
    //   .replace(/(^|\next line *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
    //   .replace(/(^|\nextline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
    //   .replace(/(^|\neckline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
    //   .replace("full stop", ".")
    //   .replace(/\,/gi, "")
    //   // .replace(/\,/gi, "")
    //   .replace(/one/gi, "1")
    //   .replace(/two/gi, "2")
    //   .replace(/three/gi, "3")
    //   .replace(/four/gi, "4")
    //   .replace(/five/gi, "5")
    //   .replace(/six/gi, "6")
    //   .replace(/seven/gi, "7")
    //   .replace(/eight/gi, "8")
    //   .replace(/nine/gi, "9")
    //   .replace(/ full stop | fullstop | full stop|fullstop/gi, ".")
    //   .replace(/ next line. | nextline. | next line | next line | nextline| next line|next line|nextline| neckline |neckline/gi, ".\n")
    //   .replace(/open bracket. |open bracket, |open bracket.|open bracket,|open bracket/gi, "(")
    //   // .replace(/\(\s?\,?\.?\s?)/g, "(") Refer for Information: https://regex101.com/r/WJ3I0y/1
    //   .replace(/ ,close bracket| close bracket|close bracket/gi, ")")
    //   .replace(/\.\.\.|\.\./gi, ".").replace(/\. \./gi, ".").replace(/ \./gi, ".")
    //   .replace(/\>\.|\> \./gi, ">").replace(/\> /gi, ">")
    //   .replace(/\?\.|\.\?/gi, "?")
    //   .replace(/centimetres|centimetre|CM|sem/gi, "cm")
    //   .replace(/ +/g, ' ');

    let text = input.replace(/(^|\. *)([a-z])/g, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\ next line. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\ nextline. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\next line *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\nextline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\neckline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/one/gi, "1")
      .replace(/two/gi, "2")
      .replace(/three/gi, "3")
      .replace(/four/gi, "4")
      .replace(/five/gi, "5")
      .replace(/six/gi, "6")
      .replace(/seven/gi, "7")
      .replace(/eight/gi, "8")
      .replace(/nine/gi, "9")
      .replace(/(\,*)(\.*)(\ *)(full)(\ )?(stop)(\,*)(\.*)/gi, ".")
      // .replace(/(\ *)(\.*)(\ *)((next)|(neck))(\ )?(line)(\.*)(\ *)(\.*)(\?*)/gi, ".\n")
      .replace(/(\ *)(\.*)(\ *)((next)|(neck))(\ )?(line)(\.*)(\?*)(\ *)(\.*)(\?*)/gi, ".\n")
      .replace(/(open)(\ )?(bracket)(\ *)(\,*)(\.*)(\ *)/gi, "(")
      .replace(/(\ *)(\,*)(close)(\ )?(bracket)/gi, ")")
      .replace(/centimetres|centimetre|CM|sem/gi, "cm")
      .replace(/\.{2,}/g, '.')
      .replace(/\,{2,}/g, ',')
      .replace(/\ {2,}/g, ' ');

    return text.trim();
  }

  handleUpdatePrescriotionVisbility = () => {

    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS TRUE SHOW UPDATE PRESCRIPTION TEXTAREA AND EDITABLE AND SHOW UPDATE PRESCRIPTION BUTTON*/ }
    {/* { NOTE MESSAGE WILL CHANGE FOR COMPLETE APPOINTMENT AND UPDATE PRESCRIPTION */ }
    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS FALSE THAN CHECK UPDATE_PRESCRIPTION_DATA !== NULL - JUST TO SHOW AND NON-EDITABLE */ }
    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS FALSE THAN CHECK UPDATE_PRESCRIPTION_DATA === NULL - DONT SHOW */ }
    {/* { UPDATE PRESCRIPTION BY HITTING MARK ARRIVAL API { UPDATE PRESCRIPTION DATA NOTHING ELSE WITHOUT } */ }
    {/* { IF UPLOAD_PRESCRIPTOIN !== NULL WE WON'T SHOW UPDATE PRESCRIPTION TEXT BOX EVEN IT PRESCRIPTION_BOOK IS TRUE } */ }

    const { appointment_status, pp_dataLoaded, appointment_details } = this.state;

    if (appointment_status === 5 && pp_dataLoaded) {
      if (appointment_details.upload_prescription === null) {
        if (appointment_details.prescription_bool || (!appointment_details.prescription_bool && appointment_details.update_prescription)) {
          return true;
        }
      }
    }

    // if (appointment_status === 5 && pp_dataLoaded) {
    //   if (appointment_details.upload_prescription === null) {
    //     if (appointment_details.prescription_bool) {
    //       return true;
    //     }
    //   }
    // }

    return false;
  }

  render() {

    const { startedDianosis, startedMedicine } = this.state;

    return (
      <>
        <HeaderComponent />
        <SubHeaderComponent
          pageTitle={`${this.state.patient.name} ${
            this.state.patient.dob !== ""
              ? this.state.patient.dob + " Yrs,"
              : ""
            } ${this.state.patient.gender}`}
          subPageTitle={(this.state.patient.uhid) !== "" ? this.state.patient.uhid : ''}
          subPageTitle2={'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time)}
          PrescriptionBtn={"yes"}
          PL={this.state.appointment_details.patient_id}
          btn_name={"PAST VC PRESCRIPTIONS"}
          patient_id={this.state.patient.id}
          booking_id={this.state.appointment_details.id}
          booking_code={this.state.appointment_details.booking_code}
          onClick={this.openPreviousPrescription}
        />
        <div className={`container-fluid custom-container ${!this.state.pp_dataLoaded ? "hidden" : ""}`}>
          <div className="cf_wrapper d-flex flex-column">
            <div className={`row mt-3 ${this.state.consult_type !== 1 ? "hidden" : ""}`}>
              <div className="col-7">
                <div
                  className="video-btn"
                  id="patient_call_widget"
                  data-pid={this.state.patient.id}
                >
                  {this.state.canEndCall ? (
                    <button
                      title="Cancel Call"
                      type="button"
                      id="endconsult_call_btn"
                      className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                      onClick={() => this.endCall()}
                    >
                      <img
                        src={VideoIcon}
                        alt="video icon"
                        className="danger mr-2"
                      />
                      <img
                        src={VIconWhite}
                        alt="video icon"
                        className="danger mr-2"
                      />
                    END CALL
                    </button>
                  ) : (
                      <button
                        disabled={this.state.callPatientBtn}
                        title={
                          this.state.callPatientBtn
                            ? "Cannot call patient"
                            : "Call Patient"
                        }
                        type="button"
                        className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                        onClick={() => this.callUser()}
                      >
                        <img
                          src={VideoIcon}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        <img
                          src={VIconWhite}
                          alt="video icon"
                          className="danger mr-2"
                        />
                    VIDEO CALL TO PATIENT
                      </button>
                    )}

                  <span className="call-message">
                    {this.state.callNotificationMsg}

                    {this.state.hungupExistingCall ? (
                      <button
                        title="Cancel Call"
                        type="button"
                        className="ml-3 img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                        onClick={() => this.endCall()}
                      >
                        <img
                          src={VideoIcon}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        <img
                          src={VIconWhite}
                          alt="video icon"
                          className="danger mr-2"
                        />
                      END ONGOING CALL
                      </button>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="col">
                <div className="video-btn audio-btn">
                  <button title="Call Patient" type="button"
                    className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                    onClick={() => this.handleAudioCallPop(true)}>
                    <img src={AudioIcon} alt="video icon" className="danger mr-2" /><img src={AudioIconWhite} alt="video icon" className="danger mr-2" />
                    AUDIO CALL TO PATIENT
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              {this.state.consult_type === 1 ? (<div className="col-3">
                <div className="row video-conferencing-column">

                  {this.state.patient.number != null && this.state.video_call_driver == 2 ? (
                    <VideoConferencing
                      // ref="vcref"
                      patient={this.state.patient}
                      booking_id={this.props.location.state.booking_id}
                      location={this.props.location}
                      onChange={this.receiveInParent}
                    />
                  ) : null}

                  {this.state.patient.number != null && this.state.video_call_driver == 1 ? (
                    <QbVideoConferencing
                      // ref="vcref"
                      patient={this.state.patient}
                      booking_id={this.props.location.state.booking_id}
                      location={this.props.location}
                      onChange={this.receiveInParent}
                    />
                  ) : null}

                </div>
              </div>) : ""}
              <div className={`notes-section ${this.state.consult_type === 1 ? "col-9" : "col-12 notes-opd"}`}>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-column notes-conent">
                          <div className="bg-light doctor-notes px-3">
                            <label className="label1 mt-3">NOTES FOR DOCTOR</label>
                            <p
                              className={`doc-notes-text float-left ${
                                this.state.appointment_details.patient_note_doctor ===
                                  null
                                  ? "no-notes"
                                  : ""
                                }`}
                            >
                              {this.state.appointment_details.patient_note_doctor ||
                                "No Notes"}
                            </p>
                          </div>

                          <div className="bg-light doctor-notes doctor-documents px-3 pb-2">
                            <label className="label1 mt-3">DOCUMENTS UPLOADED</label>
                            <div className="d-flex justify-content-start align-items-center">
                              {(this.state.uploads &&
                                this.state.uploads.fileDoc &&
                                this.state.uploads.fileDoc.length > 0) ||
                                (this.state.uploads &&
                                  this.state.uploads.fileImg &&
                                  this.state.uploads.fileImg.length > 0) ? (
                                  <div className="">
                                    {this.state.uploads &&
                                      this.state.uploads.fileImg &&
                                      this.state.uploads.fileImg.length > 0
                                      ? this.state.uploads.fileImg.map((file, i) => {
                                        return (
                                          <button
                                            className="btn btn-outline-light p-0 mr-1"
                                            key={i}
                                            onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc])}
                                          >
                                            <img
                                              src={jpg}
                                              alt="pdf icon"
                                              className="pdf"
                                            ></img>
                                          </button>
                                        );
                                      })
                                      : ""}

                                    {this.state.uploads &&
                                      this.state.uploads.fileDoc &&
                                      this.state.uploads.fileDoc.length > 0
                                      ? this.state.uploads.fileDoc.map((file, i) => {
                                        return (
                                          <button
                                            className="btn btn-outline-light p-0 mr-1"
                                            key={i}
                                            onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc], true)}
                                          >
                                            <img
                                              src={pdf}
                                              alt="pdf icon"
                                              className="pdf"
                                            ></img>
                                          </button>
                                        );
                                      })
                                      : ""}
                                  </div>
                                ) : (<p className="doc-notes-text no-notes m-0">
                                  Nil
                                </p>)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">PAST VC PRESCRIPTION</label>
                          <div
                            className={`doc-notes-text float-left scroll_loader ${
                              this.state.past_prescriptions.length === 0 &&
                                this.state.pp_dataLoaded
                                ? "no-notes"
                                : ""
                              }`}
                          >
                            {this.state.past_prescriptions.length > 0
                              ? (<ul className="list-unstyled past-prescriptionlist m-0">
                                {this.state.past_prescriptions.map((elem, i) => (
                                  <li key={i} className="d-flex justify-content-between align-items-center">
                                    <div>{elem.doctor_name}</div>
                                    <div>
                                      <Moment format="h:mm A, DD MMM, Y" parse="YYYY-MM-DD HH:mm">
                                        {elem.date}
                                      </Moment>
                                    </div>
                                    <div>
                                      <button className="btn btn-link" onClick={() => this.openPrescriptionDoc(elem.id)} >VIEW Rx</button>
                                    </div>
                                  </li>
                                ))}
                              </ul>)
                              : (this.state.past_prescriptions.length === 0 && this.state.pp_dataLoaded ? "No Past Prescriptions" : "")}

                            <div className="col justify-content-center align-self-center"
                              style={{
                                display: `${!this.state.pp_dataLoaded || this.state.isPastPrescriptionRequestProcessing ? "flex" : "none"}`,
                                margin: `${!this.state.pp_dataLoaded ? "30px 0" : ""}`,
                              }}
                            >
                              {LOADER_RED}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row doctor-presandupdate">
                      <div className="col-12">
                        <div className="text-feild">
                          <label className="label1">SYMPTOMS &amp; DIAGNOSIS</label>
                          <button type="button" className={`icon-mute-btn ${startedDianosis ? 'active' : ""} ${this.state.appointment_status === 5 || startedMedicine ? "disabled" : ""}`} title="Microphone" name="diagnosis_notes" onClick={this.toggleListen}>
                            <img src="/assets/images/mic.png" alt="mic-icon" />
                            <span></span>
                          </button>
                          <textarea
                            tabIndex="1"
                            className="text-feild1"
                            rows="5"
                            cols="20"
                            placeholder="EX : weakness for 2-3 days"
                            name="diagnosis_notes"
                            ref={this.diagnosisNotesRef}
                            value={this.state.diagnosis_notes}
                            onChange={this.handleInputChange}
                            disabled={this.state.appointment_status === 5 ? true : false}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-feild">
                          <label className="label1">
                            MEDICINES &amp; TESTS ADVISED
                          </label>
                          <button type="button" className={`icon-mute-btn ${startedMedicine ? 'active' : ""} ${this.state.appointment_status === 5 || startedDianosis ? "disabled" : ""}`} title="Microphone" name="medicine_test" onClick={this.toggleListen}>
                            <img src="/assets/images/mic.png" alt="mic-icon" />
                            <span></span>
                          </button>
                          <textarea
                            tabIndex="2"
                            className='text-feild1'
                            rows='5'
                            cols='20'
                            placeholder='EX : tab lcg 5mg'
                            name='medicines_tests'
                            ref={this.medicineNotesRef}
                            value={this.state.medicines_tests}
                            disabled={this.state.appointment_status === 5 ? true : false}
                            onChange={this.handleInputChange}></textarea>
                        </div>
                      </div>
                      {this.handleUpdatePrescriotionVisbility() ? (
                        <div className="col-12">
                          <div className="text-feild">
                            <label className="label1">UPDATE PRESCRIPTION</label>
                            <textarea
                              tabIndex="1"
                              className="text-feild1"
                              rows="5"
                              cols="20"
                              placeholder="EX : weakness for 2-3 days"
                              name="update_prescription_text"
                              value={this.state.update_prescription_text}
                              onChange={this.handleInputChange}
                              disabled={!this.state.appointment_details.prescription_bool}
                            ></textarea>
                          </div>
                        </div>) : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col d-flex align-items-center justify-content-end">
                {this.state.appointment_status !== 5 ? (
                  <>
                    <span className="mr-2">On clicking “COMPLETE APPOINTMENT” the prescription will be sent to the patient; In case you wish to update your prescription, you can do so within 24 hours of completing the appointment.</span>
                    <button
                      id="completed_consult_btn"
                      className="btn btn-danger complete-btn"
                      onClick={this.handleCompleteAppointmentClick}
                      disabled={this.state.completeAppointmentBtnValid}
                    >
                      COMPLETE APPOINTMENT
                  </button>
                  </>) : (
                    this.handleUpdatePrescriotionVisbility() && this.state.appointment_details.update_prescription === null ? (
                      <>
                        <span className="mr-2">On clicking “UPDATE PRESCRIPTION” the prescription will be sent to the patient; You will not be able to make any further changes to this prescription once you update the prescription.</span>
                        <button
                          id="completed_consult_btn"
                          className="btn btn-danger complete-btn"
                          onClick={this.handleCompleteAppointmentClick}
                          disabled={this.state.updatePrescriptionBtnValid}
                        >
                          UPDATE PRESCRIPTON
                   </button>
                      </>
                    ) : "")}
              </div>
            </div>
          </div>
        </div>

        <div className="col justify-content-center align-self-center"
          style={{
            display: `${!this.state.pp_dataLoaded ? "flex" : "none"}`,
            margin: `${!this.state.pp_dataLoaded ? "30px 0" : ""}`,
          }}
        >
          {LOADER_RED}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.documentToggle}
          className={this.state.modalClass}
        >
          <ModalHeader toggle={this.documentToggle}></ModalHeader>
          <ModalBody>
            <div className="img-rotate">
              <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
              <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            </div>
            <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
              <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
              <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
            </div>
            <div className="object-wrapper ow-fullheight">
              <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
            </div>
          </ModalBody>
        </Modal>

        <Popup
          className="cancel-pop"
          open={this.state.openhandleCompleteAppointmentButtonPop}
          onClose={() => this.handleCompleteAppointmentButton(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleCompleteAppointmentButton(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                {this.handleUpdatePrescriotionVisbility() && this.state.appointment_details.update_prescription === null ? (
                  "Your signature is being added to this prescription. You will not be able to make any further changes to this prescription once you update the prescription."
                ) : (
                    "Your signature is being added to this prescription. In case you wish to update your prescription, you can do so within 24 hours of completing the appointment."
                  )}
              </p>
            </div>
            {QbHelpers.isCallActive || !this.state.QBEndCall ?
              (<div className="pr-3 button text-center">
                <button
                  className="btn btn-outline-dark cancel-btn button-in mr-2"
                  onClick={(e) =>
                    this.completeAppointment(e, this.state.appointment_details.id)
                  }
                >
                  SEND PRESCRIPTION AND CONTINUE WITH CALL
                  <span className="loader-span">{LOADER_BLACK}</span>
                </button>
                <button
                  className="btn btn-danger mt-4 mb-4 button-in"
                  onClick={(e) =>
                    this.endCallAndCompleteAppointment(e,
                      this.state.appointment_details.id
                    )
                  }
                >
                  SEND PRESCRIPTION AND END CALL
                  <span className="loader-span">{LOADER}</span>
                </button>

              </div>)
              : this.state.QBEndCall ?
                (<div className="pr-3 button text-center">
                  <button
                    className="btn btn-danger mt-4 mb-4 button-in"
                    onClick={(e) =>
                      this.completeAppointment(e,
                        this.state.appointment_details.id
                      )
                    }
                  >
                    {" "}
                  SEND PRESCRIPTION TO PATIENT
                  <span className="loader-span">{LOADER}</span>
                  </button>
                </div>) : ""
            }
          </div>
        </Popup>


        {/* <Popup
          className="cancel-pop"
          open={this.state.openPrescriptionSentPop}
          onClose={() => this.handlePrescriptionSentPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handlePrescriptionSentPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                Your prescription has been sent to the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">
              <button
                className="btn btn-outline-dark cancel-btn button-in mr-2"
                onClick={() =>
                  this.handlePrescriptionSentPop(false)
                }
              >
                CONTINUE WITH CALL
              </button>
              <button
                className="btn btn-danger mt-4 mb-4 button-in"
                onClick={() =>
                  this.completeAppointment(
                    this.state.appointment_details.id
                  )
                }
              >
                END CALL
              </button>

            </div>
          </div>
        </Popup> */}

        <Popup
          className="cancel-pop"
          open={this.state.openAudioCallPop}
          onClose={() => this.handleAudioCallPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleAudioCallPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                You will receive a call on your mobile phone, please answer to connect with the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">

              <button
                className="btn btn-danger mt-4 mb-4 button-in mr-2"
                onClick={this.handleAUdioCallApi}
              >
                CONNECT WITH PATIENT
                <span className="loader-span">{LOADER}</span>
              </button>

              <button
                className="btn btn-outline-dark cancel-btn button-in"
                onClick={() => this.handleAudioCallPop(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </Popup>

        <Modal isOpen={this.state.handleCallFeedbackPopup}>
          <ModalHeader
            className="text-center bg-grey">Please rate the quality of your call</ModalHeader>
          <ModalBody className="text-center">
            <div className="beautify_wrapper d-flex flex-column">
              <BeautyStars
                activeColor="#d24350"
                inactiveColor="#F0F0F0"
                value={this.state.doctor_rating}
                onChange={doctor_rating => this.setDoctorRating(doctor_rating)} />
              <span className="mt-3 hidden error-beautystars text-danger">Please rate to Submit!</span>
            </div>
            <br />
            <Input type="textarea" placeholder="Remarks" name="doctor_remarks" value={this.state.doctor_remarks} rows={3}
              onChange={this.handleInputChange} />
          </ModalBody>
          <div className="d-flex text-center modal-header p-0">
            <div className="row justify-content-center">
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-cancel font-weight-bold"
                  type="button" onClick={this.handleDontRate} >
                  Not now
                </Button>
              </div>
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-red mb-3 font-weight-bold btn btn-primary"
                  type="button" onClick={this.handleBeautyPopupSubmit} >
                  Submit
                <span className="loader-span">{LOADER}</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />

        <Bandwidth booking_id={this.props.location.state.booking_id} />

      </>
    );
  }
}

export default withRouter(DocComp);