import React from "react";

export default class VcSounds extends React.Component {
    render() {
        return (
            <>
                {/* Call Sounds */}
                <audio id="endCallSignal" preload="auto">
                    <source src="/audio/end_of_call.ogg" type="audio/ogg" />
                    <source src="/audio/end_of_call.mp3" type="audio/mp3" />
                </audio>
                <audio id="callingSignal" loop preload="auto">
                    <source src="/audio/calling.ogg" type="audio/ogg" />
                    <source src="/audio/calling.mp3" type="audio/mp3" />
                </audio>
                <audio id="ringtoneSignal" loop preload="auto">
                    <source src="/audio/ringtone.ogg" type="audio/ogg" />
                    <source src="/audio/ringtone.mp3" type="audio/mp3" />
                </audio>
            </>
        )
    }
}