/**
 *  Application Constants
 *
 *  @author Arifa Arifin Ahmed <arifa@innocirc.com>
 *  @version 1.0.0 (8-April-2020)
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 *
 *  @description
 *  Application Constants for VC Desk App.
 *
 */

import React from 'react';
import $ from 'jquery';

export const APP_NAME = 'VC_Desk';
export const APP_BASE_PATH = '/vc/login';
/** Changed URL from android to Web as discussed with Abhijit */
// --------------------------------Dev APIs

// export const API_BASE_URL = 'https://dev.mhvc.appapis.desktop.myhealthcare.co/api/doctors/web/v1/doctors/';
// export const API_BASE_URL2 = 'https://dev.mhvc.appapis.desktop.myhealthcare.co/';
// export const API_BASE_URL3 = 'https://dev.mhvc.appapis.desktop.myhealthcare.co/api/doctors/web/v1/';
// export const API_BASE_URL4 = 'https://dev.mhvc.appapis.desktop.myhealthcare.co/api/doctors/web/v1/patient/';
// export const API_BASE_URL5 = 'https://dev.mhvc.appapis.desktop.myhealthcare.co/';
// export const APPOINTMENT_PRESCRIPTION = `https://dev.mhvc.appapis.desktop.myhealthcare.co/bookings/`;
// export const AGENTPORTAL_URL = 'https://dev.admin.desktop.myhealthcare.co/login';
// export const MAX_PRESCRIPTION = `http://223.30.137.86:801/Portal/Myhealth_Eprescription_link.aspx?bookingno=`;

// export const EXOTEL_KEYS = ['fa899a1e8d54cff18cd518f030b2d8f4189a44f96b9c9f60', '67878bde04baef31a655ef27ce8c6cdb3a20a9171260dc43'];

/* MyHealthCare Staging (MHVC DEV APPAPIS) Server  */
// export const API_BASE_URL = 'https://dev.appapis.desktop.myhealthcare.co/api/doctors/web/v1/doctors/';
// export const API_BASE_URL2 = 'https://dev.appapis.desktop.myhealthcare.co/';
// export const API_BASE_URL3 = 'https://dev.appapis.desktop.myhealthcare.co/api/doctors/web/v1/';
// export const API_BASE_URL4 = 'https://dev.appapis.desktop.myhealthcare.co/api/doctors/web/v1/patient/';
// export const API_BASE_URL5 = 'https://dev.appapis.desktop.myhealthcare.co/';
// export const APPOINTMENT_PRESCRIPTION = `https://dev.appapis.desktop.myhealthcare.co/bookings/`;
// export const AGENTPORTAL_URL = 'https://dev.admin.desktop.myhealthcare.co/login';
// export const VONAGE_INCOMING_CALL_SOCKET = "https://socket.vonage.myhealthcare.co/"; // "http://15.206.125.54:7001/";
// //export const MAX_PRESCRIPTION = `http://223.30.137.86:801/Portal/Myhealth_Eprescription_link.aspx?bookingno=`;

/* MyHealthCare LIVE (MHVC DEV APPAPIS) Server  */
export const API_BASE_URL = 'https://mhappapis.vc.myhealthcare.co/api/doctors/web/v1/doctors/';
export const API_BASE_URL2 = 'https://mhappapis.vc.myhealthcare.co/';
export const API_BASE_URL3 = 'https://mhappapis.vc.myhealthcare.co/api/doctors/web/v1/';
export const API_BASE_URL4 = 'https://mhappapis.vc.myhealthcare.co/api/doctors/web/v1/patient/';
export const API_BASE_URL5 = 'https://mhappapis.vc.myhealthcare.co/';
export const APPOINTMENT_PRESCRIPTION = `https://mhappapis.vc.myhealthcare.co/bookings/`;
export const AGENTPORTAL_URL = 'https://mh.vc.myhealthcare.co/login';
export const VONAGE_INCOMING_CALL_SOCKET = "https://call.vonage.myhealthcare.co/"; // "http://15.206.164.210/";
// export const MAX_PRESCRIPTION = `http://223.30.137.86:801/Portal/Myhealth_Eprescription_link.aspx?bookingno=`;

export const LOGIN_API = `${API_BASE_URL}login-otp`;
export const PIN_REQ_API = `${API_BASE_URL}request-pin`;
export const PIN_VERIFY_API = `${API_BASE_URL}verify-pin`;
export const PIN_SAVE_API = `${API_BASE_URL}save-pin`;
export const OTP_API = `${API_BASE_URL}verify-otp`;
export const LOGOUT_URL = `${API_BASE_URL}logout`;
export const HOSPITAL_LIST_API = `${API_BASE_URL}hospital?`;
export const Patient_List_API = `${API_BASE_URL}appointments?`;
export const APPOINTMENT_DETAILS = `${API_BASE_URL3}appointment?`;
export const APPOINTMENT_PRESCRIPTION_DETAILS = `${API_BASE_URL3}patient-prescription?`;
export const CANCEL_APPOINTMENT = `${API_BASE_URL}cancel-appointment`;
export const COMPLETE_APPOINTMENT = `${API_BASE_URL}mark-arrived`;
export const DOCTORS_HOSPITAL_DAY = `${API_BASE_URL3}doctors/hospital?date=`;
export const DOCTORS_MONTHLIST = `${API_BASE_URL}monthlist?request_date=`;
export const DOCTORS_APPOINTMENTS = `${API_BASE_URL3}doctors/appointments?date=`;
export const ISD_CODES = `${API_BASE_URL}isd_code`;
export const PATIENT_SEARCH = (params) => `${API_BASE_URL4}search?mobile_no=${params.mobile_no}&hospital_id=${params.id}&isd_code=${params.isd_code}`;
export const DOCTORS_APPOINTMENT_BOOKING = `${API_BASE_URL}book-appointment/`;
export const PAY_ON_ARRIVAL = (params) => `${API_BASE_URL}pay-on-arrival?booking_id=${params.booking_id}`;
export const EXOTEL_URL = `${API_BASE_URL3}call-exotel`;
export const CALL_RATING = `${API_BASE_URL3}call`;
export const SITE_CONFIGURATION = `${API_BASE_URL3}configurations`;
export const CALL_RATING_FEEDBACK = `${API_BASE_URL3}last-feedback-detail`;
export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const MISSED_APPOINTMENT_TIME = 120;
export const LATE_APPOINTMENT_TIME = 15;
export const LOADER = (<div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>);
export const LOADER_BLACK = (<div className="spinner black"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>);
export const LOADER_RED = (<div className="spinner red"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>);
export const BOOKING_STATUSES = { 'ALL': 0, 'COMPLETED': 1, 'UPCOMING': 6, 'MISSED': 4, 'CANCELLED': 3, 'ARRIVED': 5 };
export const BOOKING_STATUSES_API = { 0: 'all', 1: 'completed', 6: 'upcoming', 4: 'no_show', 3: 'cancelled', 5: 'arrived' };
export const BOOKING_STATUSES_CARD = { 0: '', 1: 'completed', 6: 'upcoming', 4: 'missed', 3: 'cancelled', 5: 'completed' };
export const SEPARATEUPLOADEDDOCS = docs => { let uploadedImages = []; let uploadedDocs = []; if (docs && docs.length > 0) { docs.forEach(value => { const fileExt = value.file.split(".").slice(-1)[0]; if (fileExt === "jpg" || fileExt === "jpeg" || fileExt === "png") uploadedImages.push(UPDATEFILEURL(value.file)); else if (fileExt === "pdf") uploadedDocs.push(UPDATEFILEURL(value.file)); }); } return { fileImg: uploadedImages, fileDoc: uploadedDocs } };
export const SEPARATEUPLOADEDDOCSPAST = (docs, booking_id) => { let uploadedImages = []; let uploadedDocs = []; if (docs && docs.length > 0) { docs.forEach(value => { const fileExt = value.file.split(".").slice(-1)[0]; if (value.booking_id === booking_id && (fileExt === "jpg" || fileExt === "jpeg" || fileExt === "png")) uploadedImages.push(UPDATEFILEURL(value.file)); else if (value.booking_id === booking_id && fileExt === "pdf") uploadedDocs.push(UPDATEFILEURL(value.file)) }); } return { fileImg: uploadedImages.slice(-3), fileDoc: uploadedDocs.slice(-3) } };
export const UPDATEFILEURL = url => { return url.replace("//2020", "/2020"); };
export const GETGENDER = gender => { if (gender === "Male") return "M"; else if (gender === "Female") return "F"; else if (gender === "Other") return "O"; else return ""; };
export const JQUERYLABELS = thisElem => { let getEle = $(thisElem).prop("tagName"); if (getEle === "INPUT" || getEle === "TEXTAREA") { let gatPlaceholder = $(thisElem).attr("placeholder"); if (typeof gatPlaceholder != "undefined" && gatPlaceholder !== "") { $(thisElem).parent().addClass("hasdata"); } $(thisElem).on("focusout", function () { if ($(thisElem).val() === "") { $(thisElem).parent().removeClass("hasdata"); } }); } if (getEle === "SELECT") { if ($(thisElem).find("option:selected")) { let Slabel = $(thisElem).find("option:selected").attr("label"); if (typeof Slabel == "undefined") { $(thisElem).parent().addClass("hasdata"); } else { $(thisElem).parent().removeClass("hasdata"); } } $(thisElem).on("change", function () { let Slabel = $(thisElem).find("option:selected").attr("label"); if (typeof Slabel == "undefined") { $(thisElem).parent().addClass("hasdata"); } else { $(thisElem).parent().removeClass("hasdata"); } }); } };
export const UHID = uhid => { if (uhid !== "" && uhid !== "--" && uhid !== null) { return uhid; } return ""; }
export const GETDOB = date => { if (!date || date === "" || date === null) return ""; date = PARSEDATE(date); const diff_ms = Date.now() - new Date(date).getTime(); const age_dt = new Date(diff_ms); return Math.abs(age_dt.getUTCFullYear() - 1970); };
export const PARSEDATE = dateStr => { const dateParts = dateStr.includes("/") ? dateStr.split("/") : dateStr.split("-").reverse(); return new Date(dateParts[2], (dateParts[1] - 1), dateParts[0]) };
export const BOOKINGTIME24TO12 = booking_time => { if (!booking_time) return ''; const date = new Date("May 25, 2020 " + booking_time); const options = { hour: 'numeric', minute: 'numeric', hour12: true }; return date.toLocaleString('en-US', options); }
export const HANDLEIMAGEROTATE = elem => { const targetName = elem.currentTarget; const imgElem = document.querySelector('.modal-imgdocs object'); if (targetName.getAttribute("name") === "rotate-left") { if (imgElem.dataset.rotate === "" || imgElem.dataset.rotate === undefined) { $(imgElem).css('transform', 'rotate(-90deg)'); imgElem.dataset.rotate = -90; } else { const currentVal = imgElem.dataset.rotate; $(imgElem).css('transform', `rotate(${currentVal - 90}deg)`); imgElem.dataset.rotate = (currentVal - 90); } } else { if (imgElem.dataset.rotate === "" || imgElem.dataset.rotate === undefined) { $(imgElem).css('transform', 'rotate(90deg)'); imgElem.dataset.rotate = 90; } else { const currentVal = imgElem.dataset.rotate; $(imgElem).css('transform', `rotate(${+currentVal + 90}deg)`); imgElem.dataset.rotate = (+currentVal + 90); } } };
export const IMAGEPREVNEXTRESET = (file) => { if (document.querySelector('[data-rotate]') !== null) { document.querySelector('[data-rotate]').dataset.rotate = "0"; document.querySelector('[data-rotate]').style = ""; document.querySelector('.object-wrapper').style.visibility = null; document.querySelector('object').setAttribute("data", file); document.querySelector('.object-wrapper').style.visibility = 'visible'; } }
export const GETURLEXTENSION = (data) => { if (data !== "") { const ext = data.substring(data.lastIndexOf('.') + 1, data.length); if (ext === "pdf") return `application/${ext}`; else return `image/${ext}` } };
