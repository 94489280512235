import * as React from 'react';
import {
    withRouter
} from "react-router-dom";
import Popup from "reactjs-popup";
import logo from "../../assets/images/logo-myhealth.png";
import './bandwidth.css';
import axios from "axios";
import {
    CALL_RATING,
    SITE_CONFIGURATION
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";

class Bandwidth extends React.Component {

    state = {
        show: false,
        floater: false,
        booking_id: null,
        requiredSpeed: null
    };

    constructor(props) {
        super(props);
        this.modal = this.modal.bind(this);
        this.speedTest = this.speedTest.bind(this);
    }

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
        this.setState({
            booking_id: this.props.booking_id
        });
        this.getSiteConfiguration();
    }

    getSiteConfiguration() {
        // const ls_speed = LS_SERVICE.get('min_bandwidth');
        // if (ls_speed != undefined) {
        //     this.setState({
        //         requiredSpeed: ls_speed
        //     }, () => {
        //         this.speedTest();
        //     });
        //     return;
        // }

        const defaultBandwidth = 512;
        axios
        .get(SITE_CONFIGURATION)
        .then((Response) => {
            let bandwidth = Response.data.minimum_bandwidth || defaultBandwidth + ' Kbps'
            let speed = bandwidth.split(' ')[0];
            // LS_SERVICE.set('min_bandwidth', speed);
            this.setState({
                requiredSpeed: speed
            }, () => {
                this.speedTest();
            });
        })
        .catch((error) => {
            this.setState({
                requiredSpeed: defaultBandwidth
            }, () => {
                this.speedTest();
            });
        });
    }

    speedTest() {
        var $this = this;
        this.setState({
            floater: true
        });
        var imageAddr = window.location.protocol + "//" + window.location.host + "/assets/images/bndwth.jpg";
        var downloadSize = 4995374; //bytes
        var startTime, endTime;
        var download = new Image();
        
        startTime = (new Date()).getTime();
        var cacheBuster = "?nnn=" + startTime;
        download.src = imageAddr + cacheBuster;
        
        download.onerror = function (err, msg) {
            console.log(">> Invalid image, or error downloading");
            $this.setState({
                floater: false
            });
        }

        download.onload = function () {
            endTime = (new Date()).getTime();

            var duration = (endTime - startTime) / 1000;
            var bitsLoaded = downloadSize * 8;
            var speedBps = (bitsLoaded / duration).toFixed(0);
            var speedKbps = (speedBps / 1024).toFixed(0);
            var speedMbps = (speedKbps / 1024).toFixed(0);
            console.log('>> Speed Test', speedBps, speedKbps, speedMbps);
            if (parseFloat(speedKbps) < parseFloat($this.state.requiredSpeed)) {
                $this.setState({
                    show: true
                });
                $this.makeLogForSpeed(speedKbps);
            }
            $this.setState({
                floater: false
            });
        }
    }

    makeLogForSpeed(speedKbps) {
        axios
        .post(CALL_RATING, {
            booking_id: this.state.booking_id,
            state: 2, // 2 for feedback Submit 1 for Call end and 0 for call start
            internet_speed: speedKbps
        })
        .then((Response) => {})
        .catch((error) => {});
    }

    modal(flag) {
        this.setState({
            show: flag
        });
    }

    render() {
        return (
            <>
                <Popup
                    open={this.state.show}
                    modal
                >
                    <div className="popup">
                        <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.modal(false)}>
                            &times;
                        </div>
                        <div className="text-center bandwidth-modal">
                            <img src={logo} width="100" className="bandwidth-logo" />
                            <p>You seem to be in an area with very low<br/> bandwidth for a video call.</p>
                            <p className="btns-margin">Please move to a place where the bandwidth is<br/> stronger or switch to wi-fi.</p>
                            <button onClick={() => this.modal(false)} className="btn btn-outline-danger bandwidth-ok">OK</button>
                            <button onClick={() => this.modal(false)} className="btn btn-outline-danger btn-danger white-btn bandwidth-ok">SWITCH TO AUDIO CALL</button>
                        </div>
                    </div>
                </Popup>

                <div className={`bandwidth-floater ${this.state.floater ? '' : 'hidden'}`} title="Checking internet bandwidth">
                    <img src="/assets/images/bandwidth.gif" />
                </div>
            </>
        )
    }

}

export default withRouter(Bandwidth);
