import React, { Component } from "react";
import HeaderComponent from "../header/headerComponent";
import SubHeaderComponent from "../sub-header/subHeaderComponent";
import ToastComponent from "../../components/toast/toast";
import axios from "axios";
import $ from "jquery";
import LS_SERVICE from "../../utils/localStorage";
import { API_BASE_URL3, JQUERYLABELS } from "../../utils/constant";
import { withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./edit.css";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: LS_SERVICE.get("doctor").first_name,
      middle_name: LS_SERVICE.get("doctor").middle_name,
      last_name: LS_SERVICE.get("doctor").last_name,
      gender: this.props.location.state.doctor_details.doctor_details.gender.id,
      // dob: this.props.location.state.doctor_details.doctor_details.dob,
      dob: this.props.location.state.doctor_details.doctor_details.dob ? new Date(this.dateFormat(this.props.location.state.doctor_details.doctor_details.dob)) : null,
      mobile_no: this.props.location.state.doctor_details.doctor_details
        .woodlands_mobile,
      email: this.props.location.state.doctor_details.doctor_details
        .woodlands_email,
      qualification: this.props.location.state.doctor_details.doctor_details.qualification.replace(
        /<[^>]+>/g,
        ""
      ),
      designation: this.props.location.state.doctor_details.doctor_details
        .designation,
      experience: this.props.location.state.doctor_details.doctor_details
        .experience,
      doctor_registration_number: this.props.location.state.doctor_details.doctor_details
        .doctor_registration_number,
      specialities: this.props.location.state.doctor_details.doctor_details
        .specialities,
      profile_text: this.props.location.state.doctor_details.doctor_details.profile_text,
      profile_image: this.props.location.state.doctor_details.doctor_details.profile_picture,
      profile_image_preview: null,
      profile_image_preview_old: this.props.location.state.doctor_details.doctor_details.profile_picture,
      doctor_details: this.props.location.state.doctor_details.doctor_details,
      upload_image_name: null,
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
      startDate: new Date(this.dateFormat(this.props.location.state.doctor_details.doctor_details.dob))
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.backButton = this.backButton.bind(this);
  }

  componentDidMount() {
    const fields = $(".emrfrm .form-control");
    fields.each((i, e) => {
      JQUERYLABELS(e);
    });
    $(document).on("focusin", ".emrfrm .form-control", function () {
      JQUERYLABELS(this);
    });
  }

  getGender(gender) {
    if (gender === "Male") return 0;
    else if (gender === "Female") return 1;
    else return 2;
  }

  resetToastState() {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    });
  };

  updateProfile() {
    this.resetToastState();
    const formData = new FormData();
    formData.append("first_name", this.state.first_name ?? '');
    formData.append("middle_name", this.state.middle_name ?? '');
    formData.append("last_name", this.state.last_name ?? '');
    formData.append("gender", this.state.gender);
    formData.append("dob", this.state.dob !== null ? (this.state.dob).toISOString().split('T')[0] : '');
    formData.append("mobile_no", this.state.mobile_no ?? '');
    formData.append("email", this.state.email ?? '');
    formData.append("qualification", this.state.qualification);
    formData.append("designation", this.state.designation ?? '');
    formData.append("experience", this.state.experience ?? '');
    formData.append("doctor_registration_number", this.state.doctor_registration_number);
    formData.append("specialities", this.state.specialities ?? '');
    formData.append("profile_text", this.state.profile_text ?? '');
    if (typeof this.state.profile_image != 'string')
      formData.append(
        "profile_picture",
        this.state.profile_image,
        this.state.profile_image.name
      );

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );
    // axios.defaults.headers.common["Content-Type"] = "form-data;";
    axios
      .post(API_BASE_URL3 + "doctors/profile/update", formData, {
        headers: {
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((Response) => {
        var doctor = LS_SERVICE.get("doctor");
        doctor.first_name = this.state.first_name;
        doctor.middle_name = this.state.middle_name;
        doctor.last_name = this.state.last_name;
        doctor.profile_picture = this.state.profile_image_preview;
        if (this.state.gender === 0)
          doctor.gender = "Male";
        else if (this.state.gender === 1)
          doctor.gender = "Female";
        else
          doctor.gender = "Other";
        doctor.name =
          "Dr " + this.state.first_name + " " + (this.state.middle_name ? this.state.middle_name + " " : '') + this.state.last_name;
        LS_SERVICE.set("doctor", doctor);
        this.props.history.push("/profile");
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 400) {
          console.log(error.response.data.errors[0].message)
          this.setState({
            toast: true,
            toastmsg: error.response.data.errors[0].message
          })
        }
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  backButton = () => {
    this.props.history.push("/profile");
  };

  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleFileChange(event) {
    this.setState({
      profile_image: event.target.files[0] ? event.target.files[0] : this.state.profile_image_preview_old,
      profile_image_preview: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : this.state.profile_image_preview_old,
      upload_image_name: event.target.files[0] ? event.target.files[0].name : '',
    });
  }
  handleDateChange = date => {
    this.setState({
      dob: date
    });
  };

  dateFormat(date) {
    if (typeof date !== undefined && date !== null) {
      date = date.split("/");
      return date[2] + "-" + date[1] + "-" + date[0];
    }
    else
      return null;
  }

  render() {
    return (
      <>
        <HeaderComponent />
        <SubHeaderComponent pageTitle={"Edit profile"} />
        <div
          className="container-fluid custom-container mb-5"
          style={{ textAlign: "left" }}
        >
          <div
            className="edit-feilds emrfrm"
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    placeholder="First Name"
                    value={this.state.first_name}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="first_name">
                    First Name*
                    </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="middle_name"
                    name="middle_name"
                    className="form-control"
                    placeholder="Middle Name"
                    value={this.state.middle_name}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="middle_name">
                    Middle Name
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="last_name">
                    Last Name
                    </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <select
                    className="form-control"
                    name="gender"
                    placeholder="gender"
                    id="gender"
                    value={this.state.gender}
                    onChange={this.handleChange}
                  >
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                    <option value="2">Other</option>
                  </select>
                  <label className="required" htmlFor="gender">
                    Gender<span>*</span>
                  </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group hasdata">
                  {/*<input
                        type="text"
                        id="dob"
                        name="dob"
                        className="form-control"
                        placeholder="Date Of Birth"
                        value={this.state.dob}
                        onChange={this.handleChange}
                    />*/}
                  <DatePicker
                    className="form-control"
                    selected={this.state.dob}
                    onChange={this.handleDateChange}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    style={{ width: "100%" }}
                  />
                  <label className="required" htmlFor="dob">
                    Date Of Birth
                    </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email ID"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="email">
                    Email ID
                    </label>
                </div>
              </div>

            </div>
            {/*<div className="col-4">
                <div className="input-group mb-3">
                <div className="input-group-prepend">
                                        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">+91</button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="#">+93</a>
                                            <a className="dropdown-item" href="#">+92</a>
                                        </div>
                                    </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    name="mobile_no"
                    placeholder="mobile_no"
                    id="mobile_no"
                    value={this.state.mobile_no}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="email">
                    Mobile No
                  </label>
                </div>
              </div>

            <div className="row">
              {/*<div className='col-4'>
                                <div className="form-group">
                                    <input type="text" id="template_name" name="template_name" className="form-control" placeholder="Designation"  value={this.state.designation} onChange={this.handleChange}/>
                                    <label className="required" htmlFor="template_name">Designation</label>
                                </div>
                            </div>*/}
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="qualification"
                    name="qualification"
                    className="form-control"
                    placeholder="Qualifications"
                    value={this.state.qualification}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="qualification">
                    Qualifications*
                    </label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="experience"
                    name="experience"
                    className="form-control"
                    placeholder="Experience"
                    value={this.state.experience}
                    onChange={this.handleChange}
                  />
                  <label className="required" htmlFor="experience">
                    Years of Experience
                    </label>
                </div>
              </div>
              {/*<div className="row ml-3 mr-3 ">
                  <div className='col-4'>
                                <div className="form-group">
                                    <input type="text" id="template_name" name="template_name" className="form-control" placeholder="Designation"  value={this.state.designation} onChange={this.handleChange}/>
                                    <label className="required" htmlFor="template_name">Designation</label>
                                </div>
                            </div>*/}
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    id="doctor-code"
                    name="doctor-code"
                    className="form-control"
                    placeholder="Registration No"
                    value={this.state.doctor_registration_number}
                    disabled
                  />
                  <label className="required" htmlFor="doctor-code">
                    Registration No
                    </label>
                </div>
              </div>

              {/*<div className='col-2'>
                                <div className="form-group">
                                    <input type="text" id="template_name" name="template_name" className="form-control" placeholder="Registration No"  value={this.state.doctor_registration_number} onChange={this.handleChange}/>
                                    <label className="required" htmlFor="template_name">Registration No</label>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className="form-group">
                                    <input type="text" id="template_name" name="template_name" className="form-control" placeholder="Form ID"  value={this.state.specialities} onChange={this.handleChange}/>
                                    <label className="required" htmlFor="template_name">Speciality</label>
                                </div>
                            </div>*/}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="5"
                    // id="inputGroupFile01"
                    name="profile_text"
                    placeholder="Profile"
                    id="profile_text"
                    defaultValue={this.state.profile_text}
                    onChange={this.handleChange}
                  ></textarea>
                  <label className="required" htmlFor="profile_text">
                    Profile
                      </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      onChange={this.handleFileChange}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                    >
                      {this.state.upload_image_name ? this.state.upload_image_name : 'Choose Profile Image'}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <Image alt="doc-image"
                  className="profile-pic"
                  src={this.state.profile_image_preview == null ? this.state.profile_image : this.state.profile_image_preview}
                  roundedCircle
                />
              </div>
            </div>
            {/*<div className='row ml-3 mr-3'>
                  <div className="col-4">
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            onChange={this.handleFileChange}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile01"
                        >
                          Choose Profile Image
                        </label>
                      </div>
                    </div>
                  </div>
                </div>*/}
          </div>
          <div className="text-center">
            <button
              className=" btn btn-default btn-outline-secondary mr-5 mt-5 text-center"
              style={{
                width: "95px",
                // border: "1px solid #d14451",
                fontWeight: "bold",
                borderRadius: "7px",
              }}
              onClick={this.backButton}
            >
              BACK
                </button>
            <button
              className=" btn btn-danger mt-5 text-center"
              style={{
                width: "95px",
                backgroundColor: "#d24350",
                border: "1px solid #d14451",
                fontWeight: "bold",
                borderRadius: "7px",
              }}
              onClick={this.updateProfile}
            >
              SAVE
                </button>
          </div>
        </div>
        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </>
    );
  }
}


export default withRouter(Edit)

// function jqueryFormField(thisElem) {
//   var getEle = $(thisElem).prop("tagName");
//   if (getEle === "INPUT" || getEle === "TEXTAREA") {
//     var gatPlaceholder = $(thisElem).attr("placeholder");
//     if (typeof gatPlaceholder != "undefined" && gatPlaceholder !== "") {
//       $(thisElem).parent().addClass("hasdata");
//     }

//     $(thisElem).on("focusout", function () {
//       if ($(thisElem).val() === "") {
//         $(thisElem).parent().removeClass("hasdata");
//       }
//     });
//   }
//   if (getEle === "SELECT") {
//     if ($(thisElem).find("option:selected")) {
//       var Slabel = $(thisElem).find("option:selected").attr("label");
//       if (typeof Slabel == "undefined") {
//         $(thisElem).parent().addClass("hasdata");
//       } else {
//         $(thisElem).parent().removeClass("hasdata");
//       }
//     }
//     $(thisElem).on("change", function () {
//       var Slabel = $(thisElem).find("option:selected").attr("label");
//       if (typeof Slabel == "undefined") {
//         $(thisElem).parent().addClass("hasdata");
//       } else {
//         $(thisElem).parent().removeClass("hasdata");
//       }
//     });
//   }
// };
