import React from "react";
import logo from "../../assets/images/logo-myhealth.png";
import loginImg from "../../assets/images/ic-loginimage.png";
import LoginForm from "../../components/loginForm/loginForm";
import poweredByLogo from '../../assets/images/main-logo.png'
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class Login extends React.Component {
  state = {
    uid: "",
    modal: false
  };

  // changeHandler = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };
  
  termsconditionToggle = (event) => {
    event.preventDefault();
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { uid } = this.state;
    return (
      <>
      <div className="container-fluid">
        <div className="full-height">
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <div className="login-section">
                <div className="login-logo">
                  <img src={logo} alt="logo" className="logo" />
                </div>
                <h5 className="virtual-heading mt-4">
                  {" "}
                  VIRTUAL CONSULT PLATFORM FOR DOCTORS
                </h5>
                <div className="login-form-container">
                  <LoginForm uid={uid} />
                </div>
                <p className="t-n-c">
                  By logging in, you agree to our 
                  &nbsp;
                  <a className="login-tc" rel="noopener noreferrer" href="!#" onClick={this.termsconditionToggle}><u>Terms &amp; Conditions</u></a>
                  <br/>
                  and &nbsp; 
                  <a className="login-tc" rel="noopener noreferrer" href="https://www.myhealthcare.co/privacy-policy/" target="_blank"><u>Privacy Policy</u></a>
                </p>
                <p className="text-secondary font-weight-bold mb-4 mt-4 text-center">
                  OR
                </p>
                <p className="login-mail">
                  Please connect with your hospital admin team to<br></br>
                  join the platform or email
                  <a className="text-danger-mail" href={"mailto:mailto:assist@myhealthcare.life"}>&nbsp;<u>assist@myhealthcare.life</u></a>
                </p>      
                <div className='powered'>
                  <p >powered by</p>
                  <img src={poweredByLogo}></img>
                </div>
              </div>
            </div>
            <div className="col-6 p-0">
              <div className="login-imgwrapper">
                <img src={loginImg} className="login-img hidden" alt="login-img"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={this.state.modal}
        toggle={this.termsconditionToggle}
        className="t-c-popup"
      >
        <ModalHeader toggle={this.termsconditionToggle}>Virtual Consult Appointment Doctor&rsquo;s Platform Terms and Conditions</ModalHeader>
        <ModalBody>
            <div className="modalwrapper">
                <p>By using the platform you hereby confirm that:</p>
                <ol>
                  <li>You are a Registered Medical Practitioner who is enrolled in the Indian Medical Register and / or the State Medical Register under the under the Indian Medical Council Act 1956.</li>
                  <li>You have read and understood the Telemedicine Practice Guidelines dated 25 March 2020 issued by the Board of Governors ("<strong>Guidelines</strong>") (in supersession of the erstwhile Medical Council of Indian guidelines), which are  applicable to you, and you confirm to abide by these Guidelines.</li>
                  <li>You agree to comply with the Indian Medical Council (Professional Conduct, Etiquette and Ethics) Regulations 2002 ("<strong>Regulations</strong>").</li>
                  <li>You agree to display the registration number accorded to you by the Medical Council of Indian and/or the State Medical Council, on prescriptions, website, electronic communication and receipts etc. given to your patients.</li>
                  <li>You would begin each consultation by informing the patient of your name and qualifications.</li>
                  <li>You would explicitly ask the age of each patient, and if there is any doubt, seek age proof. Where the patient is a minor, after confirming the age, you will proceed with a virtual consultation only if the minor is consulting along-with an adult (guardian or relative) whose identity will also be ascertained by you.</li>
                  <li>You will take an explicit consent on mobile app, email, text or audio/video message, if a virtual consultation is initiated by you. Record of each such explicated consent shall be maintained by you along with each patient’s records.  However, If, the patient initiates the telemedicine consultation, then the consent is implied.</li>
                  <li>You would prescribe medicines set out in List O, List A and List B as appropriate for each patient  with the appropriate / provisional diagnosis as per the Guidelines.</li>
                  <li>You shall ensure that each prescription is also as per the Regulations and will not contravene the provisions of Drugs and Cosmetic Act, 1940 and the relevant rules made thereunder (as amended for time to time). Further you shall not prescribe medicines set out in the Prohibited List in the Guidelines.</li>
                  <li>In all cases of emergency, you would advise the patient for an in-person interaction with a Registered Medical Practitioner at the earliest.</li>
                  <li>If in your clinical / professional judgement, you feel the patient cannot be provided a diagnosis or medication basis the virtual consultation, you shall mention so in the prescription (which you shall maintain as part of each patient’s records) and inform the patient that he or she should visit a hospital or appropriate medical facility.</li>
                  <li>You will not insist on virtual consultation, when the patient is willing to travel to a facility and/or requests an in-person consultation.</li>
                  <li>You will ensure confidentiality and not misuse patient images, data, especially private and sensitive in nature in any manner whatsoever.</li>
                  <li>You will not solicit patients for virtual consultation through any advertisements or inducements whatsoever.</li>
                  <li>You shall maintain all records, documents logging / recordings of virtual consultation / interaction with each patient, patient records, reports, documents, images, diagnostics, data (digital &nbsp; non-digital) and/or prescription records (as applicable) for each virtual consultation with a patient through the MyHealthcare virtual platform.</li>
                  <li>You will complete a mandatory online course developed and made available by the Board of Governors in supersession of Medical Council of India within 3 years of the Notification of the Guidelines for such virtual consultations and provide proof thereof to MyHealthcare virtual platform for its records.</li>
                  <li>In using MyHealthcare virtual platform, you will uphold the same professional and ethical norms and standards as applicable to traditional in-person care, within the intrinsic limitations of telemedicine and you will make all efforts to gather sufficient medical information about each patient’s condition before exercising your clinical / professional judgement.</li>
                </ol>
            </div>
        </ModalBody>
      </Modal>
    </>
    );
  }
}

export default Login;